import {useRef} from "react";

// noinspection SpellCheckingInspection
/**
 * @author Piotr Siupa
 * https://stackoverflow.com/a/66139558
 * 2021
 */
export function useFirstRender() {
    const ref = useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return firstRender;
}

export type TypeWithAuto<T> = T | "auto";

export function getTypeOrAutoValue<T>(value: TypeWithAuto<T>, auto: T): T {
    if (typeof value === "string" && value === "auto") {
        return auto;
    }
    return value;
}

export function getTypeAutoOrUndefinedValue<T>(value: TypeWithAuto<T>, auto: T, undefinedFallback: T): Exclude<T, undefined> {
    if (typeof value === "string" && value === "auto") {
        return auto as any;
    }
    else if (value === undefined) {
        return undefinedFallback as any;
    }
    return value as any;
}

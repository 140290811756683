import {FC, useEffect, useRef} from "react";
import styled from "styled-components";
import {Project} from "../Project";
import Dexie from "dexie";
import {ProjectData} from "../ProjectData";
import {useAdvancedState} from "../../../../ardai/hooks/StaticStateHook";
import {MenuButton} from "../../../../ardai/components/MenuButton";
import {DefaultButton} from "../../../../ardai/components/DefaultButton";
import {ButtonModalCompound} from "../../../../ardai/components/ButtonModalCompound";
import {Modal} from "../../../../triton/components/dialogsV2/Modal";
import {AnimatePresence, motion} from "framer-motion";
import {Tag} from "../../../../ardai/components/Tag";
import {BooleanContext} from "../../../../triton/components/BooleanContext";
import {Widget, WidgetUtils} from "../../../../portfolio/v2/components/Widget";
import {Enclave} from "../../../../portfolio/v2/components/Enclave";
import {CloseRounded} from "@mui/icons-material";
import ClippedWidgetBody = WidgetUtils.ClippedWidgetBody;
import {ClickAwayListener} from "@mui/base";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";
import {StringField} from "../../../../ardai/testing/StringField";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {useForceRenderFunc} from "../../../../ForceRerenderHook";
import {SingleLineInput} from "../../../../triton/components/forms/SingleLineInput";
import ReactJson from "react-json-view";
import {CodeEditor} from "../../../../base/components/base/CodeEditor";
import usePromise from "react-promise";
import {SDPromptField} from "../../../../imageSorter/sdInterface/SDPromptField";

const StyledProjectTestMain = styled.div`
    width: 100%;
    height: 100%;    
    display: grid;
    gap: 16px;
    grid-template-columns: 300px auto;
    padding-bottom: 16px;
`;

export type ProjectTestMainState = {
    openedFileID?: string,
    activeFileSrc?: string,
}

export const ProjectTestMain: FC = props => {

    // const [projectData, ctx] = useAdvancedState<ProjectData>({
    //     staticMode: true,
    //     id: "ProjectTestMain123",
    //     initial: {
    //         id: "_uninitialized_",
    //         name: "_uninitialized_",
    //         filesystem: {
    //             files: [],
    //             structure: {}
    //         }
    //     }
    // }).stateWithCtx;

    const projectRef = useRef(new Project({
        loadAndSaveAdapter: {
            async load(): Promise<ProjectData> {
                const json = window.localStorage.getItem("ProjectTestMain1")
                if (json === null) {
                    return {
                        id: "_uninitialized_",
                        name: "_uninitialized_",
                        filesystem: {
                            files: [],
                            directoryStructure: {
                                root: {
                                    id: "root",
                                    files: [],
                                    subDirectories: []
                                }
                            },
                            structure: {}
                        }
                    }
                }
                return JSON.parse(json);
            },
            async save(project: Project): Promise<void> {
                const savedProjectData = await project.deflate();
                window.localStorage.setItem("ProjectTestMain1", JSON.stringify(savedProjectData));
                // ctx.update(savedProjectData);
            }
        }
    }));

    const forceRender = useForceRenderFunc();

    useEffect(() => {
        projectRef.current.load().then(() => {})
    }, []);

    useEffect(() => projectRef.current.events
        .observeGlobally()
        .on(forceRender)
        .destructor,
        [projectRef.current]
    );

    const [state, ctx] = useAdvancedState<ProjectTestMainState>({
        initial: {

        }
    }).stateWithCtx;

    const activeFile = state.openedFileID ? projectRef.current.fs.getFileOptimistically(state.openedFileID) : undefined;

    useEffect(() => {
        if (!activeFile) {
            ctx.update({
                activeFileSrc: undefined
            });
        } else {
            activeFile.getSrc().then(r => {
                ctx.update({
                    activeFileSrc: r
                });
            });
        }
    // }, [activeFile?.data.id]);
    }, [activeFile]);

    return (
        <StyledProjectTestMain>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                overflowY: "scroll"
            }}>
                <DefaultButton children={"load"} onClick={() => projectRef.current.load()}/>

                <DefaultButton children={"save"} onClick={() => projectRef.current.save()}/>

                <DefaultButton
                    children={"duplicate current file"}
                    deactivated={!activeFile}
                    onClick={() => {
                        const name = activeFile!.data.name;
                        projectRef.current.fs.duplicateFile(activeFile!.data.id, `${name} (copy)`).then(() => {

                        });
                    }}
                />

                <DefaultButton
                    children={"delete current file"}
                    deactivated={!activeFile}
                    onClick={() => {
                        ctx.update({
                            openedFileID: undefined
                        });
                        projectRef.current.fs.deleteFile(state.openedFileID!)
                    }}
                />

                <DefaultButton
                    children={"delete all files"}
                    deactivated={(projectRef.current.fs?.allFileDataEntries.length ?? 0) === 0}
                    onClick={() => {
                        ctx.update({
                            openedFileID: undefined
                        });
                        const fs = projectRef.current.fs;
                        fs.deleteAllFiles();
                    }}
                />

                <BooleanContext children={(bool, setBool) => (
                    <>
                        <DefaultButton children={"create new file"} onClick={() => setBool(true)}/>

                        <AnimatePresence>
                            { bool && (
                                <Modal children={modalCtx => (
                                    <ClickAwayListener onClickAway={() => setBool(false)}>
                                        <motion.div
                                            style={{
                                                position: "fixed",
                                                top: "50%",
                                                left: "50%",
                                                x: "-50%",
                                            }}
                                            initial={"off"}
                                            exit={"off"}
                                            animate={"on"}
                                            variants={{
                                                on: {
                                                    y: "-50%",
                                                    opacity: 1,
                                                },
                                                off: {
                                                    y: 200,
                                                    opacity: 0,
                                                }
                                            }}
                                        >

                                            <Widget width={400} height={"100%"}>
                                                <Enclave position={1} children={
                                                    <motion.div
                                                        onClick={() => setBool(false)}
                                                        whileHover={{
                                                            scale: 1.1
                                                        }}
                                                        whileTap={{
                                                            scale: .9
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        children={<CloseRounded/>}
                                                    />
                                                }/>

                                                <ClippedWidgetBody>
                                                    <div style={{
                                                        padding: 20,
                                                        display: "grid",
                                                        gap: 16
                                                    }}>
                                                        <MainTypography text={"Create file"}/>

                                                        <StringField name={"name"} data={modalCtx.state.name} onChange={name => modalCtx.stateCtx.update({
                                                            name
                                                        })}/>


                                                        <DefaultButton variant={"primary"} children={"Create"} size={"small"} onClick={() => {
                                                            projectRef.current.fs.createFile(modalCtx.state.name);
                                                            setBool(false);
                                                        }}/>
                                                    </div>
                                                </ClippedWidgetBody>
                                            </Widget>


                                        </motion.div>
                                    </ClickAwayListener>
                                )}/>
                            )}
                        </AnimatePresence>
                    </>
                )}/>


                { projectRef.current.fs?.allFileDataEntries.map(fd => (
                    <div key={fd.id} style={{
                        display: "grid"
                    }}>
                        <DefaultButton variant={state.openedFileID === fd.id ? "primary" : "default"} onClick={() => {
                            ctx.update({
                                openedFileID: state.openedFileID === fd.id ? undefined : fd.id
                            });
                        }} children={
                            <MainTypography text={`${fd.name} (${fd.id.slice(0, 8)})`}/>
                        }/>
                    </div>
                )) }

                {/*
                <ReactJson src={projectData} theme={"grayscale"} enableClipboard={false}/>
                */}
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
            }}>
                { activeFile && (
                    <>
                        <SDPromptField
                            onMount={editor => editor.focus()} value={state.activeFileSrc}
                            onChange={value => {
                                activeFile?.write(value ?? "");
                                projectRef.current.save().then(() => {});
                            }}
                        />
                    </>
                ) }
            </div>
        </StyledProjectTestMain>
    );
}

import { useMediaQuery } from 'react-responsive'

export const useIsDesktopOrLaptop = () => useMediaQuery({
    query: '(min-width: 1224px)'
});

export const useIsBigScreen = () => useMediaQuery({
    query: '(min-width: 1824px)'
});

export const useIsTabletOrMobile  = () => useMediaQuery({
    query: '(max-width: 1224px)'
});

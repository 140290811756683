import {createBridge, ReactBackend} from "../../ReactAPIBridgeUtils";
import {Sitemap} from "./Sitemap";

export type PortfolioAPIV2State = {};

export class PortfolioAPIV2 extends ReactBackend<PortfolioAPIV2State> {

    public readonly location: string = "/dev/simple-component-test";

    public readonly sitemap: Sitemap = Sitemap.from({
        label: "Home",
        subSites: {
            "projects": {
                label: "Projects",
                subSites: {
                    "personal": {
                        label: "Personal projects",
                    }
                }
            },
            "404": {
                label: "Not Found",
            }
        }
    });

    public getLocalPath(): string {
        return window.location.pathname.replace(this.location, "/");
    }
}

export const PortfolioAPIV2Bridge = createBridge<PortfolioAPIV2State, PortfolioAPIV2>({
    state: {},
    backend: () => new PortfolioAPIV2
});

import {FC} from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";
import {useTriton} from "../../../../../triton/TritonHooks";
import {Triton} from "../../../../../triton/Triton";

export type LabelledValueCardProps = {
    value: string,
    label: string
}

const StyledLabelledValueCard = styled.div<{
    t: Triton
}>`
    width: 100%;
    border-radius: 8px;
    background-color: ${p => p.t.col("bg_modal")};
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    user-select: none;
`;

export const LabelledValueCard: FC<LabelledValueCardProps> = props => {
    const t = useTriton();
    return (
        <StyledLabelledValueCard t={t}>
            <DescriptiveTypography text={props.value} style={{
                fontSize: 10,
                fontWeight: "bold",
                // fontSize: 12,
            }}/>
            <DescriptiveTypography text={props.label} style={{
                fontSize: 10,
                // justifySelf: "flex-end"
            }}/>
        </StyledLabelledValueCard>
    );
}

import React, {FC, PropsWithChildren} from "react";
import {Page} from "../Page";
import {Text} from "../components/Text";
import styled from "styled-components";
import {Widget, WidgetProps, WidgetUtils} from "../components/Widget";
import {Enclave, EnclavePosition} from "../components/Enclave";
import {Tag, TagVariants} from "../components/Tag";
import {WidgetBasedCardVariants} from "../components/WidgetBasedCard";
import {useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {ToggleController, ToggleControllerBridge} from "../../../triton/components/advanced/toggle/ToggleController";
import {ToggleTarget} from "../../../triton/components/advanced/toggle/ToggleTarget";
import {useTriton} from "../../../triton/TritonHooks";
import {Interpolate} from "../../../triton/components/advanced/Interpolate";
import {useForceRenderFunc} from "../../../ForceRerenderHook";
import {AutoAwesomeRounded} from "@mui/icons-material";

const StyledPageProjects = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;
    justify-content: space-between;
`;

export const PageProjects: FC = props => {
    const navigate = useNavigate();
    const t = useTriton();

    return (
        <Page enableDefaultPadding>
            <StyledPageProjects>
                <div style={{
                    width: "100%",
                    overflowX: "hidden",
                    display: "grid",
                    gridTemplateColumns: "min-content auto",
                    gap: 20,
                }}>
                    <div>
                        <Text fontSize={"x-large"} whiteSpaceNoWrap color={"auto"} text={"All my projects at a glance"}/>
                        <Text fontSize={"x-large"} whiteSpaceNoWrap color={"auto"} text={"Projects"} style={{
                            color: t.col("color_primary")
                        }}/>
                    </div>
                </div>

                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    gap: 40
                }}>
                    <div style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "start",
                        flexDirection: "row",
                        gap: 20
                    }}>
                        {/*
                        https://i.pinimg.com/736x/8c/26/92/8c2692987741569fdac6821d78547643.jpg
                        */}
                        <ToggleController>

                            <ProjectCard
                                group={"nodes"}
                                title={"Continuum"}
                                description={"A node-ui for simulating complex logic"}
                                widgetPropsOverrides={{
                                    width: 300
                                }}
                                additionalWidgetChildren={
                                    <Enclave position={EnclavePosition.TOP_LEFT}>
                                        <Tag backgroundColor={"#1E1E1E"} style={{
                                            height: 30
                                        }}>
                                            <AutoAwesomeRounded sx={{
                                                color: "white",
                                                fontSize: 18,
                                                marginRight: "-8px"
                                            }}/>
                                            <Text
                                                fontWeight={"lighter"}
                                                noSelect whiteSpaceNoWrap text={"NEW"} fontSize={"small"} color={"auto"}/>
                                        </Tag>
                                    </Enclave>
                                }
                            >
                                <img
                                    alt={""}
                                    width={"100%"}
                                    src={"https://i.pinimg.com/736x/8c/26/92/8c2692987741569fdac6821d78547643.jpg"}
                                />
                            </ProjectCard>

                            <div style={{
                                height: "50%",
                                width: 1,
                                backgroundColor: t.col("fg_muted"),
                                alignSelf: "center",
                                margin: 5,
                            }}/>

                            <ProjectCard
                                group={"portfolio"}
                                title={"Portfolio"}
                                description={"Written in TS using React"}
                                children={
                                    <img
                                        alt={""}
                                        width={"100%"}
                                        src={"https://i.pinimg.com/736x/8c/26/92/8c2692987741569fdac6821d78547643.jpg"}
                                    />
                                }/>

                            <ProjectCard
                                group={"sql"}
                                title={"SQL-Editor"}
                                description={"Fullstack SQL sandbox environment"}
                                children={
                                    <img
                                        alt={""}
                                        width={"100%"}
                                        src={"https://i.pinimg.com/736x/8c/26/92/8c2692987741569fdac6821d78547643.jpg"}
                                    />
                                }/>
                        </ToggleController>
                    </div>

                    <ToggleController>
                        <div style={{
                            display: "grid",
                            // height: 125,
                            gridTemplateColumns: "auto auto",
                            gap: 30,
                        }}>
                            <ToggleTarget group={"professional-work"} children={ctx => (
                                <Widget width={250} height={125} widgetWrapper={(widget, widgetContext) => (
                                    <motion.div
                                        children={widget}
                                        onHoverStart={ctx.requestFocus}
                                        onHoverEnd={ctx.requestUnFocus}
                                        onAnimationComplete={widgetContext.requestRerender}
                                        style={{
                                            y: 0,
                                        }}
                                        animate={{
                                            filter: `grayscale(${
                                                ctx.isAnyActive ? (
                                                    ctx.isActive ? 0 : 100
                                                ) : 0
                                            }%)`,
                                            y: ctx.isAnyActive ? (
                                                ctx.isActive ? -2.5 : 2.5
                                            ) : 0,
                                        }}
                                        whileTap={{
                                            y: 0
                                        }}
                                    />
                                )}>
                                    <Enclave position={1}>
                                        <TagVariants.TagWithText
                                            text={"->"}
                                            backgroundColor={"#1E1E1E"}
                                        />
                                    </Enclave>

                                    <WidgetUtils.ClippedWidgetBody style={{
                                        cursor: "pointer",
                                        userSelect: "none",
                                    }}>
                                        <div style={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 4,
                                            justifyContent: "end",
                                            padding: 20,
                                            backgroundColor: "#1E1E1E"
                                        }}>
                                            <Text text={"2"} color={"auto"} fontSize={"medium"}/>
                                            <Text text={"Professional work"} color={"auto"} style={{
                                                color: t.col("color_primary")
                                            }}/>
                                        </div>
                                    </WidgetUtils.ClippedWidgetBody>
                                </Widget>
                            )}/>

                            <ToggleTarget group={"personal"} children={ctx => (
                                <Widget width={125} height={125} widgetWrapper={(widget, widgetContext) => (
                                    <motion.div
                                        children={widget}
                                        onHoverStart={ctx.requestFocus}
                                        onHoverEnd={ctx.requestUnFocus}
                                        onAnimationComplete={widgetContext.requestRerender}
                                        style={{
                                            y: 0,
                                        }}
                                        animate={{
                                            filter: `grayscale(${
                                                ctx.isAnyActive ? (
                                                    ctx.isActive ? 0 : 100
                                                ) : 0
                                            }%)`,
                                            y: ctx.isAnyActive ? (
                                                ctx.isActive ? -2.5 : 2.5
                                            ) : 0,
                                        }}
                                        whileTap={{
                                            y: 0
                                        }}
                                    />
                                )}>
                                    <Enclave position={1}>
                                        <TagVariants.TagWithText
                                            text={"->"}
                                            backgroundColor={"#1E1E1E"}
                                        />
                                    </Enclave>

                                    <WidgetUtils.ClippedWidgetBody style={{
                                        cursor: "pointer",
                                        userSelect: "none",
                                    }} onClick={() => navigate("personal")}>
                                        <div style={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 4,
                                            justifyContent: "end",
                                            padding: 20,
                                            backgroundColor: "#1E1E1E"
                                        }}>
                                            <Text text={"15"} color={"auto"} fontSize={"medium"}/>
                                            <Text text={"Sketches"} color={"auto"} style={{
                                                color: t.col("color_primary")
                                            }}/>
                                        </div>
                                    </WidgetUtils.ClippedWidgetBody>
                                </Widget>
                            )}/>
                        </div>
                    </ToggleController>
                </div>

            </StyledPageProjects>
        </Page>
    );
}

const ProjectCard: FC<PropsWithChildren<{
    group: string,
    title?: string,
    description?: string,
    widgetPropsOverrides?: Partial<WidgetProps>
    additionalWidgetChildren?: React.ReactNode
}>> = props => {
    const {group} = props;

    return (
        <ToggleTarget group={group} children={ctx => (
            <Widget width={250} height={250} widgetWrapper={(widget, widgetContext) => (
                <motion.div
                    children={widget}
                    onHoverStart={ctx.requestFocus}
                    onHoverEnd={ctx.requestUnFocus}
                    onAnimationComplete={widgetContext.requestRerender}
                    style={{
                        y: 0,
                    }}
                    animate={{
                        filter: `grayscale(${
                            ctx.isAnyActive ? (
                                ctx.isActive ? 0 : 100
                            ) : 0
                        }%)`,
                        y: ctx.isAnyActive ? (
                            ctx.isActive ? -2.5 : 2.5
                        ) : 0,
                    }}
                    whileTap={{
                        y: 0
                    }}
                />
            )} {...props.widgetPropsOverrides}>

                {props.additionalWidgetChildren}

                <Enclave position={EnclavePosition.TOP_RIGHT} children={
                    <div style={{
                        aspectRatio: "1 / 1",
                        width: 30,
                        position: "relative",
                    }}>
                        <motion.div style={{
                            backgroundColor: "#1E1E1E",
                            borderRadius: "50%",
                            aspectRatio: "1 / 1",
                            width: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            position: "absolute",
                        }} whileHover={{
                            top: -2.5,
                            right: -2.5,
                        }} whileTap={{
                            scale: 1.2
                        }} children={
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect y="6.10352e-05" width="13.9819" height="0.874996" fill="#D9D9D9"/>
                                <rect x="13.9819" y="6.10352e-05" width="13.9999" height="0.873867"
                                      transform="rotate(90 13.9819 6.10352e-05)" fill="#D9D9D9"/>
                                <rect width="14.1626" height="0.874431"
                                      transform="matrix(-0.70665 0.707563 -0.70665 -0.707563 14 0.618713)"
                                      fill="#D9D9D9"/>
                            </svg>
                        }/>
                    </div>
                }/>


                <WidgetUtils.ClippedWidgetBody style={{
                    cursor: "pointer",
                }}>
                    {props.children}

                    <motion.div style={{
                        zIndex: 1,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        background: "linear-gradient(transparent, black)",
                    }} animate={{
                        opacity: ctx.isActive ? .75 : .5,
                    }}/>

                    <motion.div
                        style={{
                            zIndex: 2,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            padding: 20,
                            pointerEvents: "none"
                        }}
                    >
                        <Text text={props.title} color={"auto"} fontSize={18}/>

                        <AnimatePresence>
                            { ctx.isActive && (
                                <motion.div
                                    layout
                                    style={{
                                        overflow: "hidden",
                                    }}
                                    animate={{
                                        height: "auto",
                                        opacity: 1
                                    }}
                                    initial={"off"}
                                    exit={"off"}
                                    variants={{
                                        off: {
                                            height: 0,
                                            opacity: 0
                                        }
                                    }}
                                >
                                    <div style={{
                                        paddingTop: 8
                                    }}>
                                        <Text text={props.description} color={"auto"} fontSize={"small"} fontWeight={"normal"}/>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                    </motion.div>
                </WidgetUtils.ClippedWidgetBody>
            </Widget>
        )}/>
    );
}

import React, {PropsWithChildren, useEffect} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {Formik} from "formik";
import {FormikSingleLineInput} from "../../triton/components/forms/FormikSingleLineInput";
import {StyledModal} from "../../ardai/components/StyledModal";
import Dialog from "@mui/material/Dialog";
import {ButtonBase, ButtonVariant} from "../../triton/components/buttons/ButtonBase";
import {Grid} from "../../triton/components/layouts/Grid";
import axios from "axios";
import {MenuButton} from "../../ardai/components/MenuButton";
import {Menu} from "../../ardai/components/Menu";
import {axiosExo, exo} from "../../ardspace/arc/shared/Exo";
import {CRH} from "../../ardspace/arc/shared/ConditionalResponseHandler";
import {arcURL} from "../../ardspace/arc/Globals";
import {GuardedSector} from "../../ardspace/arc/common/GuardedSector";
import {StandardizedAPIResponse} from "../../ardspace/arc/shared/StandardizedAPIResponse";
import {LeviathanMain} from "../leviathan/LeviathanMain";
import {ArdaiMain} from "../../ardai/ArdaiMain";
import {AppHub} from "../../../hub/AppHub";
import {Simulation} from "../../ship/test/core/Simulation";
import {ShipMain} from "../TestScreen";
import {Simulation2Main} from "../../ship/test2/Simulation2Main";
import {AtlasMain} from "../../atlas/AtlasMain";
import {InDevAtlasAPI} from "../../atlas/api/InDevAtlasAPI";
import {ArdspaceRoutingMain} from "../../ardspace/ArdspaceRoutingMain";
// import {ShuttleTest} from "../../shuttle/ShuttleTest";
import {StreamdeckTestMain} from "../../streamdeck/test/StreamdeckTestMain";
import {BArdai} from "../../ardai/_/beautifulArdai/BArdai";
import {DominionMain} from "../../dominion/DominionMain";
import {PortfolioV1Main} from "../../portfolio/v1/PortfolioV1Main";
import {StandaloneNodeSandbox} from "../../node/tests/StandaloneNodeSandbox";
import {SATTestMain} from "../../sat/SATTestMain";
import {TestLayout} from "../../utils/ui/layout/tests/TestLayout";
import {SimpleComponentTestingSpace} from "../SimpleComponentTestingSpace";

export const AuthTestMain: React.FC = props => {
    return (
        <AttemptAutoSessionStart children={
            <Routes>

                <Route path={"/"} element={
                    <PortfolioV1Main/>
                }/>

                {/* App hub (Select the program you want / Get info about the environment in general) */}
                <Route path={"apps/"} element={
                    <GuardedSector
                        notAllowedComponent={<Navigate to={"/"}/>}
                        strategy={() => true}
                        children={<AppHub/>}
                    />
                }/>

                {/* Standalone node test */}
                <Route path={"nodes/*"} element={
                    <StandaloneNodeSandbox/>
                }/>

                {/* Ardspace Social Media project */}
                <Route path={"/ardspace/*"} element={
                    <ArdspaceRoutingMain/>
                }/>

                <Route path={"/b/*"} element={
                    <BArdai/>
                }/>

                {/* ardspace-ai */}
                <Route path={"/ai/*"} element={
                    <ArdaiMain/>
                }/>

                {/* shuttle test */}
                {/*
                <Route path={"/shuttle/*"} element={
                    <ShuttleTestMain/>
                }/>
                */}


                <Route path={"/leviathan"} element={
                    <GuardedSector
                        notAllowedComponent={<Navigate to={"/"}/>}
                        strategy={() => true}
                        children={<LeviathanMain/>}
                    />
                }/>
                <Route path={"/atlas"} element={
                    <AtlasMain api={new InDevAtlasAPI()}/>
                }/>
                <Route path={`/sim-a`} element={
                    <ShipMain/>
                }/>
                <Route path={`/sim-b`} element={
                    <Simulation fps={2} printCycleTime={false}/>
                }/>
                <Route path={`/sim-c`} element={
                    <Simulation2Main/>
                }/>


                <Route path={`/deck`} element={
                    <StreamdeckTestMain/>
                }/>

                <Route path={`/dominion`} element={
                    <DominionMain/>
                }/>

                <Route path={`/sat-test/*`} element={
                    <SATTestMain/>
                }/>

                {/* Place quick test components here */}
                {/* TODO: Wrap in GuardedSector with strategy like: is-in-dev-mode */}
                <Route path={`/dev/simple-component-test/*`} element={
                    <SimpleComponentTestingSpace/>
                }/>

                {/* Main fallback route -> App hub */}
                <Route
                    path={"*"}
                    element={<Navigate to={"/"}/>}
                />
            </Routes>
        }/>
    );
}

export const CreateAccountPage: React.FC = props => {
    const submit = (values: any) => {
        axios({
            url: arcURL("create-account"),
            method: "post",
            data: {
                username: values.username,
                password: values.password,
                email: values.email
            }
        }).then(res => {
            window.alert(JSON.stringify(res.data))
        })
    }

    const navigate = useNavigate();

    return (
        <Formik initialValues={{ email: "", username: "", password: "" }} onSubmit={submit} children={fp => (
            <Dialog open={true} children={
                <StyledModal closeDisplayMode={"hidden"} title={"Signup"} footer={
                    <Grid gTC={"repeat(2, 1fr)"} style={{ width: "100%" }}>
                        <ButtonBase variant={ButtonVariant.PRIMARY} text={"Create account"} baseProps={{ onClick: e => fp.handleSubmit(e) }}/>
                        <ButtonBase text={"Sign in"} baseProps={{ onClick: () => navigate("/login") }}/>
                    </Grid>
                }>
                    <FormikSingleLineInput formikProps={fp} name={"email"} placeholder={"Email"}/>
                    <FormikSingleLineInput formikProps={fp} name={"username"} placeholder={"Username"}/>
                    <FormikSingleLineInput formikProps={fp} name={"password"} baseProps={{ type: "password" }} placeholder={"Password"}/>
                </StyledModal>
            }/>
        )}/>
    );
}

export const LoginPage: React.FC = props => {
    const navigate = useNavigate();

    const submit = (values: any) => {
        axios({
            url: arcURL("login"),
            method: "post",
            data: {
                password: values.password,
                email: values.email
            }
        }).then(res => {
            const data = res.data;

            window.alert(`response: ${JSON.stringify(data)}`);

            if (data.code === 0 && data.payload !== null) {
                const crudeToken = data.payload.refreshToken;
                const jwt = data.payload.jwt;

                // Set crudeToken & and jwt to the local-store
                localStorage.setItem("master-token", crudeToken);
                localStorage.setItem("session-token", jwt);

                axios.defaults.headers.common = {'Authorization': `Bearer ${jwt}`}

                // Login was fully successful :: Redirect to origin page
                navigate("/")
            }
        })
    }

    return (
        <Formik initialValues={{ email: "", username: "", password: "" }} onSubmit={submit} children={fp => (
            <Dialog open={true} children={
                <StyledModal closeDisplayMode={"hidden"} title={"Login"} footer={
                    <Grid gTC={"repeat(2, 1fr) min-content"} style={{ width: "100%" }}>
                        <ButtonBase variant={ButtonVariant.PRIMARY} text={"Login"} baseProps={{ onClick: e => fp.handleSubmit(e) }}/>
                        <ButtonBase text={"Sign up"} baseProps={{ onClick: () => navigate("/signup") }}/>
                        <Menu>
                            <MenuButton text={"List all used emails"} onSelect={() => {
                                axios({
                                    url: arcURL("list-accounts"),
                                    method: "get"
                                }).then(res => window.alert(`Accounts: ${res.data}`))
                            }}/>
                            <MenuButton text={"Clear all tokens"} onSelect={() => {
                                localStorage.removeItem("master-token");
                                localStorage.removeItem("session-token");
                                window.location.reload();
                            }}/>
                        </Menu>
                    </Grid>
                }>
                    <FormikSingleLineInput formikProps={fp} name={"email"} placeholder={"Email"}/>
                    <FormikSingleLineInput formikProps={fp} name={"password"} baseProps={{ type: "password" }} placeholder={"Password"}/>
                </StyledModal>
            }/>
        )}/>
    );
}

export const HomePage: React.FC = props => {
    return (
        <Dialog open={true} children={
            <StyledModal closeDisplayMode={"hidden"} title={"Login"} footer={
                <Grid gTC={"repeat(2, 1fr) min-content"} style={{ width: "100%" }}>
                    <ButtonBase text={"Get my resOwnID"} baseProps={{
                        onClick: async () => {
                            await axiosExo({
                                url: arcURL("pong-my-resource-id"),
                                method: "get"
                            }, {
                                crh: new CRH().ok(response => {
                                    window.alert(`resourceOwnerID: '${response.payload}'`)
                                })
                            })
                        }
                    }}/>
                    <ButtonBase text={"Logout"} baseProps={{
                        onClick: async () => {
                            await axiosExo({
                                url: arcURL("logout"),
                                method: "post"
                            }, {
                                crh: new CRH().ok(response => {
                                    window.localStorage.removeItem("master-token");
                                    window.localStorage.removeItem("session-token");
                                    window.location.reload();
                                })
                            })
                        }
                    }}/>
                    <Menu>
                        <MenuButton text={"Clear all tokens"} onSelect={() => {
                            localStorage.removeItem("master-token");
                            localStorage.removeItem("session-token");
                            window.location.reload();
                        }}/>

                        <MenuButton text={"Purge all accounts"} onSelect={async () => {
                            await axiosExo({
                                url: arcURL("purge-accounts"),
                                method: "delete"
                            })
                            localStorage.removeItem("master-token");
                            localStorage.removeItem("session-token");
                            window.location.reload();
                        }}/>
                    </Menu>
                </Grid>
            }>

            </StyledModal>
        }/>
    );
}

export const CrudeGuardedSector: React.FC<PropsWithChildren> = props => {
    const hasJWT = () => !!localStorage.getItem("session-token");

    return hasJWT() ? <>{ props.children }</> : (() => {
        return (
            <Navigate to={"signup"}/>
        );
    })();
}

export const AttemptAutoSessionStart: React.FC<PropsWithChildren> = props => {
    useEffect(() => {
        // Try to start the session if the necessary master-token is present
        const masterToken = localStorage.getItem("master-token");

        if (masterToken !== null) {
            axios({
                url: arcURL("crude-authenticate"),
                method: "post",
                data: {
                    crudeToken: masterToken
                }
            }).then(res => {
                const apiRes: StandardizedAPIResponse = res.data;
                switch (apiRes.code) {
                    case 0: {
                        console.log("OK")
                        break;
                    }
                    // Invalid crude token provided
                    case -201: {
                        localStorage.removeItem("master-token");
                        localStorage.removeItem("session-token");
                        break;
                    }
                    default: {

                    }
                }
            })
        }
    }, [])

    return (
        <>{ props.children }</>
    );
}

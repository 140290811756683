import React, {FC} from "react";
import {Text} from "./components/Text";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Tag} from "../../ardai/components/Tag";
import {TagVariants} from "./components/Tag";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Breadcrumbs} from "./components/Breadcrumbs";
import {useTriton} from "../../triton/TritonHooks";
import {useIsTabletOrMobile} from "../../../std/MediaQueries";

const StyledHeader = styled.header`
    display: grid;
    grid-template-columns: min-content auto min-content;
    
    gap: 40px;
    
    padding-top: 65px;
    padding-left: 100px;
    padding-right: 100px;
`;

export const Header: FC = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const t = useTriton();

    const isMobile = useIsTabletOrMobile();

    if (isMobile) {
        return (
            <div style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}>
                <div style={{
                    display: "grid",
                    alignItems: "center",
                    gridTemplateColumns: "min-content auto",
                    overflowX: "hidden",
                    gap: "1rem",
                    width: "100%",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 20
                    }}>
                        <div style={{
                            borderRadius: "50%",
                            aspectRatio: "1 / 1",
                            width: 35,
                            backgroundColor: t.col("color_primary")
                        }}/>
                        <Text text={"Bernstein"} fontSize={"small"} color={"auto"}/>
                    </div>

                    {/* RIGHT SIDE */}
                </div>

                <Breadcrumbs/>
            </div>
        );
    }

    return (
        <StyledHeader>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 20
            }}>
                <div style={{
                    borderRadius: "50%",
                    aspectRatio: "1 / 1",
                    width: 35,
                    backgroundColor: t.col("color_primary")
                }}/>
                <Text text={"Bernstein"} fontSize={"small"} color={"auto"}/>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 20,
            }}>
                <TagVariants.TagWithText text={"Projects"} backgroundColor={"#1E1E1E"} onClick={() => {
                    navigate("projects");
                }}/>
                <TagVariants.TagWithText text={"About Me"} backgroundColor={"#1E1E1E"}/>

                <Text text={"|"}/>

                <Breadcrumbs/>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 20,
            }}>
                <TagVariants.TagWithText text={"Contact"} backgroundColor={"#1E1E1E"}/>
            </div>

        </StyledHeader>
    )
}

import {FC} from "react";
import {
    DescriptiveTypography,
    DescriptiveTypographyProps
} from "../../../triton/components/typography/DescriptiveTypography";
import {FontWeight} from "../../../base/logic/style/FontWeight";
import {getTypeOrAutoValue, TypeWithAuto} from "../../../../std/TypeWithAuto";

export type FontSize = "x-small" | "small" | "medium" | "large" | "x-large" | number

export type TextProps = DescriptiveTypographyProps & {
    fontSize?: FontSize,
    fontWeight?: FontWeight,
    bold?: boolean,
    color?: TypeWithAuto<string>
}

export const Text: FC<TextProps> = props => {

    let fontWeight: FontWeight = "bold"; // TODO: make it like: triton().fonts.stdWeight;
    if (props.fontWeight) fontWeight = props.fontWeight;
    else if (props.bold) fontWeight = "bold";

    let color = getTypeOrAutoValue(props.color, "white");

    // TODO: Make this a part of Triton theming system ->
    //  - t.fontSize("size-variant"): number | string
    let fontSize = 15;
    if (props.fontSize) {
        if (typeof props.fontSize === "number") {
            fontSize = props.fontSize;
        } else {
            switch (props.fontSize) {
                case "x-small": {
                    fontSize = 12;
                    break;
                }
                case "small": {
                    fontSize = 15;
                    break;
                }
                case "medium": {
                    fontSize = 36;
                    break;
                }
                case "large": {
                    fontSize = 48;
                    break;
                }
                case "x-large": {
                    fontSize = 72;
                    break;
                }
            }
        }
    }

    return (
        <DescriptiveTypography
            {...props}
            style={{
                fontFamily: "JetBrains Mono, monospace",
                fontVariantLigatures: "normal",
                lineHeight: 1,
                fontWeight,
                fontSize,
                color,
                ...props.style
            }}
        />
    );
}

import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {PortfolioAPIV2Bridge} from "../PortfolioAPIV2";
import styled from "styled-components";
import {TagVariants} from "./Tag";
import {ChevronRightRounded} from "@mui/icons-material";
import {AnimatePresence, motion} from "framer-motion";

export type BreadcrumbsProps = {

}

const StyledBreadcrumbs = styled.div`
    &, .breadcrumb {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    
    .breadcrumb {
    }
`

export const Breadcrumbs: FC<BreadcrumbsProps> = props => {
    const navigate = useNavigate();
    const portfolio = PortfolioAPIV2Bridge.useBackend();
    const sitemap = portfolio.sitemap;

    const locationShards = portfolio.getLocalPath().split("/").filter(s => s.length > 0);
    locationShards.unshift(""); // Index page -> "/"

    const sites = sitemap.getSitesAlongPath(locationShards.slice(1));
    const visitedPaths: Array<string> = [];

    return (
        <StyledBreadcrumbs>
            <AnimatePresence>
                { sites.map((site, idx, arr) => {
                    visitedPaths.push(locationShards[idx]);
                    const path = visitedPaths.slice(1).join("/");
                    return (
                        <motion.div
                            // key={`${idx}-${site.info.label}`}
                            key={idx}
                            className="breadcrumb"
                            animate={"on"}
                            initial={"off"}
                            exit={"off"}
                            variants={{
                                "off": {
                                    x: -50,
                                    opacity: 0,
                                },
                                "on": {
                                    x: 0,
                                    opacity: 1,
                                }
                            }}
                        >
                            <TagVariants.TagWithText
                                backgroundColor={"#1E1E1E"}
                                text={site.info.label}
                                onClick={() => navigate(path)}
                            />

                            {idx < arr.length - 1 && (
                                <ChevronRightRounded sx={{
                                    color: "white"
                                }}/>
                            )}
                        </motion.div>
                    );
                }) }
            </AnimatePresence>
        </StyledBreadcrumbs>
    );
}

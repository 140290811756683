import {FC, PropsWithChildren} from "react";
import {AnimatePresence, motion} from "framer-motion";

export type PageProps = PropsWithChildren<Partial<{
    enableDefaultPadding: boolean
}>>

export const Page: FC<PageProps> = props => {
    return (
        <AnimatePresence>
            <motion.div
                initial={"off"}
                exit={"off"}
                animate={"on"}
                style={{
                    width: "100%",
                    height: "100%",
                    overflowY: "scroll", // TODO: Make configurable
                    padding: props.enableDefaultPadding ? "65px 100px" : 0
                }}
                variants={{
                    off: {
                        y: 50,
                        opacity: 0,
                    },
                    on: {
                        y: 0,
                        opacity: 1,
                    }
                }}
            >
                {
                    props.children
                }
            </motion.div>
        </AnimatePresence>
    )
}

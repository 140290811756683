import {FC, ReactNode} from "react";
import {IStaticStateCtx, useAdvancedState} from "../../../ardai/hooks/StaticStateHook";
import {AnimatePresence} from "framer-motion";

export type ModalProps = {
    children: ReactNode | ((ctx: ModalContext) => ReactNode)
}

export const Modal: FC<ModalProps> = props => {

    const [childState, childStateCtx] = useAdvancedState<any>({
        initial: {}
    }).stateWithCtx;

    return (
        <>
            {
                typeof props.children !== "function" ? props.children : props.children({
                    state: childState,
                    stateCtx: childStateCtx
                })
            }
        </>
    );
}

export interface ModalContext {
    state: any,
    stateCtx: IStaticStateCtx<any>
}

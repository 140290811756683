import React, {CSSProperties, FC, ReactNode, useEffect, useRef} from "react";
import {CodeFragment} from "./CodeFragment";
import {Code} from "./Code";
import {useAdvancedState} from "../../../../../ardai/hooks/StaticStateHook";
import {createBridge, ReactBackend, ReactMaster} from "../../../../../ReactAPIBridgeUtils";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";
import {useFirstRender} from "../../../../std/hooks/UseFirstRenderHook";
import {useTriton} from "../../../../../triton/TritonHooks";
import {StandaloneObservable} from "../../../../../ardai/webapi/pubsub/StandaloneObservable";

export type DynamicCodeEditorProps = {
    onChange: (code: Code) => void,
    renderConfig?: Partial<DynamicCodeEditorPropsRenderConfig>,
}

export type DynamicCodeEditorPropsRenderConfig = {
    amendRow: (renderedRow: ReactNode, ctx: RowRenderContext) => ReactNode
}

export type RowRenderContext = {
    rowIdx: number,
    group: string,
    groupIdx: number
}

export type Cursor = {
    row: number,
    fragCol: number,
    col: number,
}

export type DynamicCodeEditorState = {
    code: Code,
    cursor: Cursor,
}

const DynamicCodeEditorSub: FC<DynamicCodeEditorProps> = props => {
    const ctx = DynamicCodeEditorContextBridge.useBackend();
    const t = useTriton();

    const renderConfig: DynamicCodeEditorPropsRenderConfig = {
        amendRow: p => p,
        ...props.renderConfig
    };

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                {
                    (() => {
                        const groupRowCounters: Map<string, number> = new Map<string, number>([
                            ["assembler", 0],
                            ["comment", 0],
                            ["label", 0]
                        ]);

                        const getAndIncRowCounter = (type: string): number => {
                            if (groupRowCounters.has(type)) {
                                const temp = groupRowCounters.get(type)!;
                                groupRowCounters.set(type, temp + 1);
                                return temp;
                            }
                            groupRowCounters.set(type, 1);
                            return 0;
                        }

                        return (
                            <>
                                {
                                    ctx.state.code.fragments.map((frag, rowIdx) => {
                                        const firstFrag = frag.fragments[0] ?? "";
                                        let renderedRow: ReactNode;
                                        let rowType: "assembler" | "comment" | "label" | "pragma";

                                        if (firstFrag.endsWith(":")) rowType = "label";
                                        else if (firstFrag.startsWith("#")) rowType = "comment";
                                        else if (firstFrag.startsWith("@")) rowType = "pragma";
                                        else rowType = "assembler";

                                        if (rowType === "pragma") renderedRow = (
                                            <DCECommentFragmentRenderer
                                                lineNumber={-1}
                                                key={frag.id}
                                                ctx={ctx}
                                                fragment={frag}
                                                color={t.col("color_secondary")}
                                                updateFragment={kernel => {
                                                    ctx.updateFragment(frag.id, kernel);
                                                }}
                                            />
                                        );

                                        else if (rowType === "comment" || rowType === "label") renderedRow = (
                                            <DCECommentFragmentRenderer
                                                lineNumber={-1}
                                                key={frag.id}
                                                ctx={ctx}
                                                fragment={frag}
                                                color={rowType === "comment" ? undefined : t.col("color_primary")}
                                                updateFragment={kernel => {
                                                    ctx.updateFragment(frag.id, kernel);
                                                }}
                                            />
                                        );

                                        else renderedRow = (
                                            <DCEMTAMFragmentRenderer
                                                lineNumber={groupRowCounters.get("assembler") ?? 0}
                                                key={frag.id}
                                                ctx={ctx}
                                                fragment={frag}
                                                updateFragment={kernel => {
                                                    ctx.updateFragment(frag.id, kernel);
                                                }}
                                            />
                                        );

                                        if (renderConfig.amendRow !== undefined) {
                                            renderedRow = renderConfig.amendRow(renderedRow, {
                                                rowIdx: rowIdx,
                                                group: rowType,
                                                groupIdx: getAndIncRowCounter(rowType)
                                            });
                                        }

                                        return renderedRow;
                                    })
                                }
                            </>
                        )
                    })()
                }
            </div>

            {/*
            <DescriptiveTypography
                text={`r: ${ctx.state.cursor.row} fc: ${ctx.state.cursor.fragCol} c: ${ctx.state.cursor.col}`}
                noSelect
            />
            */}
        </div>
    );
}

export const DynamicCodeEditor: FC<DynamicCodeEditorProps> = props => {
    return (
        <ReactMaster fx={DynamicCodeEditorContextBridge}>
            <DynamicCodeEditorSub {...props}/>
        </ReactMaster>
    );
}

export type DCEEventDefaultNamespace = "dispatch:focus-fragment";

export type DCEEvent<T = {}> = T & {};

export namespace DCEEvents {
    export namespace Dispatches {
        export type FocusFragmentEvent = DCEEvent<{
            rowIdx: number,
            fragColIdx: number
            colIdx: number
        }>
    }
}

export class DynamicCodeEditorContext extends ReactBackend<DynamicCodeEditorState> {

    public readonly events = new StandaloneObservable<DCEEventDefaultNamespace | string, DCEEvent>();

    public updateFragment(id: string, kernel: (prevState: CodeFragment) => Partial<CodeFragment>) {
        const idx = this.getFragmentIndexFromId(id);
        this.setState(prevState => {
            const fragments = prevState.code.fragments;
            fragments[idx] = {
                ...fragments[idx],
                ...kernel(fragments[idx])
            };
            return {
                ...prevState,
                code: {
                    fragments: [...fragments]
                }
            };
        })
    }

    public getCurrentCodeFragmentHtml() {
        return document.getElementById(this.getCodeFragmentHtmlID(this.currentFragment, this.state.cursor.fragCol))
    }

    public getCodeFragmentHtmlID(fragment: CodeFragment, colFragIdx: number = 0): string {
        return `dce-${fragment.id}-${colFragIdx}`;
    }

    public focusCodeFragment(fragment: CodeFragment, colFragIdx: number = 0, colIdx: number = 0) {
        if (fragment === undefined) return;

        // const element = document.getElementById(this.getCodeFragmentHtmlID(fragment, colFragIdx));
        // if (element === null) return;
        // (element as HTMLInputElement).setSelectionRange(colIdx, colIdx);
        // element.focus();

        this.events.notify("dispatch:focus-fragment", {
            rowIdx: this.getFragmentIndexFromId(fragment.id),
            fragColIdx: colFragIdx,
            colIdx
        } as DCEEvents.Dispatches.FocusFragmentEvent)
    }

    public getFragmentIndexFromId(id: string) {
        return this.state.code.fragments.findIndex(frag => frag.id === id);
    }

    public newFragment(fragmentConfig: Partial<CodeFragment> = {}, at: number | undefined = undefined) {
        const appendAtEnd = at === undefined;
        const fragment: CodeFragment = {
            id: v4(),
            fragments: [],
            ...fragmentConfig
        }
        if (appendAtEnd) {
            this.setState(prevState => ({
                ...prevState,
                code: {
                    fragments: [...prevState.code.fragments, fragment],
                }
            }));
            return;
        }
        throw new Error("not implemented");
    }

    public moveCursorDown(expandIfEOF: boolean = false) {
        let wasNewFragmentCreated = false;
        if (expandIfEOF && this.cursor.row + 1 === this.fragmentCount) {
            this.newFragment();
            wasNewFragmentCreated = true;
        }
        this.setState(prevState => {
            let newRow = prevState.cursor.row + 1;
            newRow = Math.min(this.fragmentCount + (wasNewFragmentCreated ? 0 : - 1), newRow);
            const targetFragment = this.getFragmentAt(newRow);
            this.focusCodeFragment(targetFragment, prevState.cursor.fragCol, prevState.cursor.col);
            return {
                ...prevState,
                cursor: {
                    ...prevState.cursor,
                    row: newRow
                }
            }
        });
    }

    public moveCursorLeft() {
        if (this.cursor.fragCol === 0) {
            return;
        }
        this.setState(prevState => {
            const newFragCol = prevState.cursor.fragCol - 1;
            const targetFragment = this.getFragmentAt(prevState.cursor.row);
            const targetFragmentColLength = (targetFragment.fragments[newFragCol] ?? "").length;
            this.focusCodeFragment(targetFragment, newFragCol, targetFragmentColLength);
            return {
                ...prevState,
                cursor: {
                    ...prevState.cursor,
                    fragCol: newFragCol
                }
            };
        })
    }

    public moveCursorRight() {
        this.setState(prevState => {
            const newFragCol = prevState.cursor.fragCol + 1;
            const targetFragment = this.getFragmentAt(prevState.cursor.row);
            this.focusCodeFragment(targetFragment, newFragCol, 0);
            return {
                ...prevState,
                cursor: {
                    ...prevState.cursor,
                    fragCol: newFragCol
                }
            };
        })
    }

    public setCursorPostion(row: number, fragCol: number = 0, col: number = 0, autoFocus: boolean = true)  {
        if (row < 0 || fragCol < 0 || col < 0) throw new Error("Could not set cursor postion");
        this.setState(prevState => {
            if (autoFocus) {
                this.focusCodeFragment(this.getFragmentAt(row), fragCol, col);
            }
            return {
                ...prevState,
                cursor: {
                    ...prevState.cursor,
                    row,
                    fragCol,
                    col
                }
            };
        })
    }

    public moveCursorUp() {
        if (this.cursor.row === 0) {
            return;
        }
        this.setState(prevState => {
            const newRow = prevState.cursor.row - 1;
            const targetFragment = this.getFragmentAt(newRow);
            this.focusCodeFragment(targetFragment, prevState.cursor.fragCol, prevState.cursor.col);
            return {
                ...prevState,
                cursor: {
                    ...prevState.cursor,
                    row: newRow
                }
            };
        })
    }

    public swapFragments(fragAIdx: number, fragBIdx: number) {
        if (fragAIdx === fragBIdx) return;
        if (fragBIdx < 0 || fragBIdx >= this.fragmentCount) return;
        if (fragAIdx < 0 || fragAIdx >= this.fragmentCount) return;
        this.setState(prevState => {
            const newFrags = [...prevState.code.fragments];
            const temp = newFrags[fragAIdx];
            newFrags[fragAIdx] = newFrags[fragBIdx];
            newFrags[fragBIdx] = temp;
            return {
                ...prevState,
                code: {
                    ...prevState.code,
                    fragments: newFrags
                }
            }
        })
    }

    public insertNewFragmentAt(rowIdx: number, fragment: Partial<CodeFragment> = {}) {
        const combinedFragment: CodeFragment = {
            id: v4(),
            fragments: [],
            ...fragment
        }

        this.setState(prevState => {
            const newFragments = [...prevState.code.fragments];
            for (let i = newFragments.length; i > rowIdx; i--) {
                newFragments[i] = newFragments[i - 1];
            }
            newFragments[rowIdx] = combinedFragment;
            return {
                ...prevState,
                code: {
                    ...prevState.code,
                    fragments: newFragments
                }
            }
        })
    }

    public getFragmentAt(rowIdx: number): CodeFragment {
        return this.state.code.fragments[rowIdx]
    }

    public get currentFragment(): CodeFragment {
        return this.state.code.fragments[this.state.cursor.row]
    }

    public get cursor(): Cursor {
        return this.state.cursor;
    }

    public get fragmentCount(): number {
        return this.state.code.fragments.length
    }
}

export const DynamicCodeEditorContextBridge = createBridge<DynamicCodeEditorState, DynamicCodeEditorContext>({
    state: {
        code: {
            fragments: [
                {
                    id: v4(),
                    fragments: []
                }
            ]
        },
        cursor: {
            row: 0,
            fragCol: 0,
            col: 0
        }
    },
    backend() {
        return new DynamicCodeEditorContext
    }
})

export type FragmentRenderPropsWithLineNumber<T = {}> = T & FragmentRenderProps<{
    lineNumber: number
}>;

export type FragmentRenderProps<T = {}> = T & {
    ctx: DynamicCodeEditorContext,
    fragment: CodeFragment,
    updateFragment: (kernel: (prevState: CodeFragment) => Partial<CodeFragment>) => void
}

export type ColumnFragmentTargetCaptureMode = "exact" | "any" | "greater-or-equal";

export const DCEFragmentInputHTMLClass = "dce-fragment-input";

function shouldFragmentInputCatchFocus(
    captureMode: ColumnFragmentTargetCaptureMode,
    thisColFragIdx: number,
    currentColFragIdx: number,
) {
    switch (captureMode) {
        case "exact":
            if (currentColFragIdx === thisColFragIdx) {
                return true;
            }
            break;
        case "any":
            return true;
        case "greater-or-equal":
            if (currentColFragIdx >= thisColFragIdx) {
                return true;
            }
            break;
    }
    return false;
}

export const FragmentInput: FC<{
    fragment: CodeFragment,
    colFrag: number,
    colFragTargetCaptureMode?: ColumnFragmentTargetCaptureMode,
    autoFocus?: boolean,
    onChange: (value: string) => void,
    isFirstFragCol?: boolean
    isLastFragCol?: boolean
    width?: number | string,
    style?: CSSProperties
}> = props => {
    const { fragment, colFrag } = props;
    const captureMode = props.colFragTargetCaptureMode ?? "exact";
    const ctx = DynamicCodeEditorContextBridge.useBackend();
    const cursor = ctx.cursor;

    const isFirstRenderCycle = useFirstRender();
    const fragRowIdx = ctx.getFragmentIndexFromId(fragment.id);
    // const isThisFragmentColTargeted = fragRowIdx === cursor.row && colFrag === cursor.fragCol;
    const isThisFragmentColTargeted = fragRowIdx === cursor.row && shouldFragmentInputCatchFocus(captureMode, colFrag, cursor.fragCol);
    const shouldAutoFocus = isThisFragmentColTargeted && isFirstRenderCycle;

    const t = useTriton();
    const value = fragment.fragments[colFrag] ?? "";

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => ctx.events.observe("dispatch:focus-fragment").on((key, data) => {
        if (inputRef.current === null) return;
        // TODO: Check if makes errors

        const event = data as DCEEvents.Dispatches.FocusFragmentEvent;
        if (event.rowIdx !== fragRowIdx) return;
        const capture = () => {
            const elem = inputRef.current!;
            elem.setSelectionRange(event.colIdx, event.colIdx);
            elem.focus();
        }

        if (shouldFragmentInputCatchFocus(captureMode, colFrag, event.fragColIdx)) {
            capture();
        }
    }).destructor, []);

    function onChange(newValue: string) {
        props.onChange(newValue)
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const inputCursorPos = e.currentTarget.selectionStart ?? 0;
        switch (e.key) {
            case "Enter": {
                if (e.ctrlKey) {
                    ctx.insertNewFragmentAt(cursor.row + 1);
                    break;
                }
                ctx.moveCursorDown(true);
                break;
            }
            case "ArrowDown": {
                if (e.shiftKey && e.ctrlKey) {
                    ctx.swapFragments(cursor.row, cursor.row + 1);
                    e.stopPropagation();
                    e.preventDefault();
                    // break;
                }
                ctx.moveCursorDown();
                break;
            }
            case "ArrowUp": {
                if (e.shiftKey && e.ctrlKey) {
                    ctx.swapFragments(cursor.row, cursor.row - 1);
                    e.stopPropagation();
                    e.preventDefault();
                    // break;
                }
                ctx.moveCursorUp();
                break;
            }
            case "ArrowLeft": {
                if (inputCursorPos === 0) {
                    e.preventDefault();
                    e.stopPropagation();
                    ctx.moveCursorLeft();
                }
                break;
            }
            case "ArrowRight": {
                if (inputCursorPos === e.currentTarget.value.length) {
                    e.preventDefault();
                    e.stopPropagation();
                    ctx.moveCursorRight();
                }
                break;
            }
            case "Backspace": {
                const isFragColBlank = e.currentTarget.value.length === 0;

                // If idx > 0: move left by one

                // If idx === 0: and all other
                break;
            }
        }
    }

    return (
        <input
            // React properties
            ref={inputRef}
            className={DCEFragmentInputHTMLClass}
            id={ctx.getCodeFragmentHtmlID(fragment, colFrag)}
            defaultValue={value}
            spellCheck={false}
            autoFocus={props.autoFocus || shouldAutoFocus}
            onChange={e => onChange(e.currentTarget.value)}
            onKeyDown={onKeyDown}
            onFocus={e => {
            // onClick={e => {
                // if (document.activeElement === e.currentTarget) return;
                // TODO: Only if not focussed
                ctx.setCursorPostion(
                    fragRowIdx,
                    colFrag,
                    e.currentTarget.selectionStart || 0
                )
            }}
            style={{
                outline: "none",
                border: "none",
                backgroundColor: isThisFragmentColTargeted ? t.colObj("fg_secondary").withAlpha(.1).css() : "transparent",
                color: t.col("fg_secondary"),
                fontWeight: "lighter",
                width: props.width ?? "100%",
                ...(props.style ?? {})
            }}
        />
    );
}

export const DCECommentFragmentRenderer: FC<FragmentRenderPropsWithLineNumber<{
    color?: string
}>> = props => {
    const { fragment } = props;
    const isFirstRenderCycle = useFirstRender();
    const isThisFragmentTargeted = props.ctx.currentFragment === fragment;

    function onChange(newValue: string) {
        props.updateFragment(() => ({
            fragments: [newValue]
        }));
    }

    return (
        <div style={{
            display: "grid",
            gap: 2,
            paddingLeft: "38px",

            gridTemplateColumns: "auto",
            // gridTemplateColumns: "auto",

            alignItems: "center",
            // backgroundColor: vm.currentInstructionCodePoint === idx ? "rgba(241, 74, 52, 0.1)" : undefined
        }}>
            {/*
            <DescriptiveTypography
                text={`${String(props.lineNumber).padStart(4, " ")} `}
                style={{
                    opacity: .4,
                    whiteSpace: "pre"
                }}
            />
            */}

            <FragmentInput
                fragment={fragment}
                colFrag={0}
                colFragTargetCaptureMode={"any"}
                onChange={onChange}
                autoFocus={isThisFragmentTargeted && isFirstRenderCycle}
                style={{
                    color: props.color ?? "#595959"
                }}
            />
        </div>
    );
}

export const DCEMTAMFragmentRenderer: FC<FragmentRenderPropsWithLineNumber> = props => {
    const { fragment } = props;
    const isFirstRenderCycle = useFirstRender();
    const isThisFragmentTargeted = props.ctx.currentFragment === fragment;

    function createChangeHandler(colIdx: number) {
        return (newValue: string) => {
            props.updateFragment(prevState => {
                const fragments = [...prevState.fragments];
                fragments[colIdx] = newValue;
                return {
                    fragments
                };
            });
        }
    }

    return (
        <div style={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "min-content 100px 50px 70px",
            alignItems: "center",
            // backgroundColor: vm.currentInstructionCodePoint === idx ? "rgba(241, 74, 52, 0.1)" : undefined
        }}>
            <DescriptiveTypography
                text={`${String(props.lineNumber).padStart(4, " ")} `}
                style={{
                    opacity: .4,
                    whiteSpace: "pre"
                }}
            />

            <FragmentInput
                fragment={fragment}
                colFrag={0}
                onChange={createChangeHandler(0)}
                // autoFocus={isThisFragmentTargeted && isFirstRenderCycle}
                isFirstFragCol
            />

            <FragmentInput
                fragment={fragment}
                colFrag={1}
                onChange={createChangeHandler(1)}
                // autoFocus={isThisFragmentTargeted && isFirstRenderCycle}
            />

            <FragmentInput
                fragment={fragment}
                colFragTargetCaptureMode={"greater-or-equal"}
                colFrag={2}
                onChange={createChangeHandler(2)}
                // autoFocus={isThisFragmentTargeted && isFirstRenderCycle}
                isLastFragCol
            />
        </div>
    );
}

import { motion } from "framer-motion";
import {FC, PropsWithChildren, ReactNode} from "react";
import {Widget, WidgetProps, WidgetUtils} from "./Widget";
import {useAdvancedState} from "../../../ardai/hooks/StaticStateHook";
import {FontSize, Text} from "./Text";
import {useTriton} from "../../../triton/TritonHooks";

export type WidgetBasedCardProps = PropsWithChildren<Partial<{
    height: string | number,
    width: string | number,
    frontCardOverride: ReactNode,
    backCardOverride: ReactNode,


    frontCardWidthDefault: string | number,
    frontCardWidthOnHover: string | number,

    widgetChildren: ReactNode,
}>>

type WidgetBasedCardState = {
    isHovered: boolean,
}

export const WidgetBasedCard: FC<WidgetBasedCardProps> = props => {
    const t = useTriton();

    const [state, ctx] = useAdvancedState<WidgetBasedCardState>({
        initial: {
            isHovered: false,
        }
    }).stateWithCtx;

    return (
        <Widget
            {...props}
            onHover={() => {
                ctx.update({
                    isHovered: true
                })
            }}
            onHoverEnd={() => {
                ctx.update({
                    isHovered: false
                })
            }}
        >
            { props.widgetChildren }

            <WidgetUtils.ClippedWidgetBody>
                <div style={{
                    height: "100%",
                    width: "100%",
                    background: t.col("color_primary"),
                    zIndex: 1,
                    position: "relative",
                    userSelect: "none",
                }}>
                    <motion.div
                        animate={state.isHovered ? "hovered" : "default"}
                        style={{
                            position: "absolute",
                            right: 0,
                            zIndex: 2,
                            background: "#1E1E1E",
                            height: "100%",
                            width: props.frontCardWidthDefault ?? "calc(100% - 20px)",
                            padding: 18,
                            borderTopLeftRadius: 17,
                            borderBottomLeftRadius: 17,
                        }}
                        variants={{
                            "hovered": {
                                width: props.frontCardWidthOnHover ?? "calc(100% - 0px)",
                            }
                        }}
                    >
                        { props.children }
                    </motion.div>
                </div>
            </WidgetUtils.ClippedWidgetBody>
        </Widget>
    );
}

export namespace WidgetBasedCardVariants {

    export const ValueWithLabel: FC<WidgetBasedCardProps & {
        value: string,
        label: string,
    } & Partial<{
        labelColor: string,
        valueFontSize: FontSize
    }>> = props => {
        const t = useTriton();
        return (
            <WidgetBasedCard {...props}>
                <div style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    justifyContent: "end"
                }}>
                    <Text text={props.value} color={"auto"} fontSize={props.valueFontSize ?? "medium"}/>
                    <Text text={props.label} color={"auto"} style={{
                        color: props.labelColor ?? t.col("color_primary")
                    }}/>
                </div>
            </WidgetBasedCard>
        );
    }
}

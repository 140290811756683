export class Site {
    subSites: Map<string, Site> = new Map<string, Site>();
    constructor(
        public info: SiteInfo = {
            label: "unnamed",
        },
    ) {}

    public getSubSite(site: string): Site | undefined {
        return this.subSites.get(site);
    }

    public hydrate(data: SitemapJSONRepresentation): this {
        this.info = {
            label: data.label ?? "unnamed",
        };
        for (const subSitesKey in data.subSites) {
            const subSiteInfo = data.subSites[subSitesKey];
            const subSite = new Site();
            subSite.hydrate(subSiteInfo);
            this.subSites.set(subSitesKey, subSite);
        }
        return this;
    }
}

export type SiteInfo = {
    label: string
}

export class Sitemap extends Site {

    public getSite(path: Array<string>): Site | undefined {
        if (path.length < 1) return this.index;
        let site: Site | undefined = this;
        while (path.length > 1) {
            if (site === undefined) break;
            site = site?.getSubSite(path.shift()!);
        }
        return site;
    }

    public getSitesAlongPath(path: Array<string>): Array<Site> {
        const sites: Array<Site> = [this.index];
        let siteTail: Site = this;
        while (path.length > 0) {
            const pathHead = path.shift()!;
            const nextSiteTail = siteTail.getSubSite(pathHead);
            if (!nextSiteTail) break;
            sites.push(nextSiteTail);
            siteTail = nextSiteTail;
        }
        return sites;
    }

    public get index(): Site {
        return this;
    }

    public static from(data: SitemapJSONRepresentation): Sitemap {
        const sitemap = new Sitemap();
        sitemap.hydrate(data);
        return sitemap;
    }
}

export type SitemapJSONRepresentation = SiteInfo & {
    subSites?: {
        [K: string]: SitemapJSONRepresentation
    }
}

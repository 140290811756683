import {FC, PropsWithChildren} from "react";
import {createBridge, ReactBackend, ReactMaster} from "../../../../ReactAPIBridgeUtils";

export type ToggleControllerProps = PropsWithChildren<{}>

export type ToggleControllerState = {
    activeGroup?: string | number
};

export const ToggleController: FC<ToggleControllerProps> = props => {
    return (
        <ReactMaster fx={ToggleControllerBridge}>
            { props.children }
        </ReactMaster>
    );
}

export class ToggleControllerBackend extends ReactBackend<ToggleControllerState> {

    public isActive(group: string | number): boolean {
        return this.state.activeGroup === group;
    }

    public toggle(group: string | number): void {
        this.setState(prevState => ({
            ...prevState,
            activeGroup: prevState.activeGroup === group ? undefined : group,
        }))
    }
}

export const ToggleControllerBridge = createBridge<ToggleControllerState, ToggleControllerBackend>({
    state: {},
    backend: () => new ToggleControllerBackend
})

import {
    createContext,
    CSSProperties,
    DetailedHTMLProps,
    FC, HTMLAttributes,
    PropsWithChildren,
    ReactNode,
    useContext,
    useEffect,
    useRef
} from "react";
import {useTriton} from "../../../triton/TritonHooks";
import styled from "styled-components";
import {Triton} from "../../../triton/Triton";
import {useFirstRender} from "../../../node/std/hooks/UseFirstRenderHook";
import {useForceRenderFunc} from "../../../ForceRerenderHook";
import { roundCorners } from "svg-round-corners";
import {v4} from "uuid";
import { motion } from "framer-motion";
const ClipperLib = require('clipper-lib');

const StyledWidget = styled.div<{
    t: Triton,
    p: WidgetProps
}>`
    border-radius: 20px;
    position: relative;
    
    // background-color: blue;
`;

export type WidgetProps = Partial<{
    width: string | number,
    height: string | number,
    // scale?: number, // TODO: Reintroduce scaling
    fill: string,
    boundingBoxPadding: number,
    borderRoundness: number,
    children: ReactNode | ((ctx: WidgetRenderContext) => ReactNode),
    additionalSVGBackgroundPatterns: ReactNode,
    widgetBackgroundPattern: ReactNode,
    onHover: () => void,
    onHoverEnd: () => void,
    widgetWrapper: (renderedWidget: ReactNode, context: WidgetRenderContext) => ReactNode,
}>;

export type WidgetRenderContext = {
    widgetId: string,
    requestRerender: () => void,
}

export const WidgetRenderContextContext = createContext<WidgetRenderContext>({
    widgetId: "all",
    requestRerender: () => {},
})

export function useWidgetRenderContext(): WidgetRenderContext {
    return useContext(WidgetRenderContextContext);
}

export const Widget: FC<WidgetProps> = props => {
    const widgetIdRef = useRef<string>(v4());
    const t = useTriton();
    const htmlRootRef = useRef<HTMLDivElement>(null);
    const svgPathRef = useRef<SVGPathElement>(null);

    let path: string | undefined = undefined;
    const isFirstRenderCycle = useFirstRender();

    let bb: BoundingBox = defaultBoundingBox();
    let bbsToSubtract: BoundingBox[] = [];
    if (!isFirstRenderCycle) {
        bbsToSubtract = getBoundingBoxesFromElements(htmlRootRef.current!).map(bb => scaleBoundingBox(bb, 0));
        bb = getBoundingBoxFromElement(htmlRootRef.current!);

        const subjectPaths = [getBoundingBoxCoordinates({
            ...bb,
            x: 0,
            y: 0
        })];
        const boundingBoxPadding = props.boundingBoxPadding ?? 5;
        const clipPaths  = bbsToSubtract
            .map(bbs => scaleBoundingBox(bbs, boundingBoxPadding))
            .map(bbs => getBoundingBoxCoordinates(bbs));

        const uppercaseResult: { X: number, Y: number }[][] = new ClipperLib.Paths();
        const clipper = new ClipperLib.Clipper();
        clipper.AddPaths(subjectPaths, ClipperLib.PolyType.ptSubject, true);
        clipper.AddPaths(clipPaths, ClipperLib.PolyType.ptClip, true);
        clipper.Execute(ClipperLib.ClipType.ctDifference, uppercaseResult);

        const result: Point[][] = uppercaseResult.map(shape => shape.map(({ X, Y }) => ({
            x: X,
            y: Y
        })))

        path = convertShapesToSVGPath(result);
        const borderRoundness = props.borderRoundness ?? 20;
        if (borderRoundness > 0) {
            path = roundCorners(path, 20).path;
        }
    }

    const context: WidgetRenderContext = {
        widgetId: widgetIdRef.current,
        requestRerender: () => forceRender()
    }

    const style: CSSProperties = {
        width: props.width,
        height: props.height,
    }

    const svgStyle: CSSProperties = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    }

    // Let child elements render, so that bounding boxes can be calculated
    const forceRender = useForceRenderFunc();
    useEffect(() => {
        forceRender()
    }, []);

    const renderedWidget = (
        <StyledWidget
            onMouseEnter={() => props.onHover?.()}
            onMouseLeave={() => props.onHoverEnd?.()}
            data-widget-id={context.widgetId}
            t={t} p={props}
            ref={htmlRootRef}
            style={style}
        >
            <WidgetRenderContextContext.Provider value={context}>
                { !isFirstRenderCycle && (
                    <svg
                        // viewBox={`0 0 ${props.width} ${props.height}`} // TODO: Check if works with % sizing

                        id={`shape-${context.widgetId}`}
                        // preserveAspectRatio={"xMidYMid meet"}
                        preserveAspectRatio={"none"}
                        style={svgStyle}
                    >
                        <defs>
                            { props.additionalSVGBackgroundPatterns }

                            <pattern
                                id={`widget-pattern-${context.widgetId}`}
                                width={bb.width}
                                height={bb.height}
                                patternUnits="userSpaceOnUse"
                            >
                                { props.widgetBackgroundPattern || (
                                    <rect
                                        x={0}
                                        y={0}
                                        width={bb.width}
                                        height={bb.height}
                                        fill={props.fill ?? "black"}
                                    />
                                )}
                            </pattern>
                        </defs>
                        <mask id={`mask-shape-${context.widgetId}`}>
                            <path
                                d={path}
                                fill={"white"}
                            />
                        </mask>
                        <path
                            ref={svgPathRef}
                            d={path}
                            fill={`url(#widget-pattern-${context.widgetId})`}
                        />
                    </svg>
                )}

                {props.children && (typeof props.children === "function" ?
                    props.children(context) :
                    props.children)
                }
            </WidgetRenderContextContext.Provider>
        </StyledWidget>
    );

    if (!props.widgetWrapper) return (
        <>{ renderedWidget }</>
    );
    else return (
        <>{ props.widgetWrapper(renderedWidget, context) }</>
    );
}

type Point = {
    x: number;
    y: number;
};

type BoundingBox = {
    x: number;
    y: number;
    width: number;
    height: number;
};

function getBoundingBoxFromElement(element: Element, offsetX = 0, offsetY = 0): BoundingBox {
    if (!(element instanceof HTMLElement)) {
        throw new Error("Provided element is not an HTMLElement.");
    }

    // Get the element's bounding rectangle
    const rect = element.getBoundingClientRect();

    // Return bounding box relative to provided offsets
    return {
        x: rect.left - offsetX,
        y: rect.top - offsetY,
        width: rect.width,
        height: rect.height,
    };
}

function getBoundingBoxesFromElements(root: Element): BoundingBox[] {
    const x = root.getBoundingClientRect().left;
    const y = root.getBoundingClientRect().top;

    // Select all elements with the class "widget-subtract-bb"
    const targetWidget = root.getAttribute("data-widget-id") ?? "all"
    const elements = root.querySelectorAll<HTMLElement>(
        `.widget-subtract-bb[data-target-widget='${targetWidget}'], .widget-subtract-bb[data-target-all-widgets]`
    );

    // Map elements to bounding boxes with coordinates relative to (x, y)
    return Array.from(elements).map((element) => {
        return getBoundingBoxFromElement(element, x, y);
    });
}

function scaleBoundingBox(box: BoundingBox, n: number): BoundingBox {
    // Calculate the center of the bounding box
    const centerX = box.x + box.width / 2;
    const centerY = box.y + box.height / 2;

    // Scale the width and height by adding 2 * n (n on each side)
    const newWidth = box.width + 2 * n;
    const newHeight = box.height + 2 * n;

    // Recalculate the top-left corner to maintain the center
    const newX = centerX - newWidth / 2;
    const newY = centerY - newHeight / 2;

    return {
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
    };
}

function getBoundingBoxCoordinates(bb: BoundingBox) {
    const { x, y, width, height } = bb;

    return [
        { X: x + width, Y: y + height }, // Bottom-right
        { X: x, Y: y + height },         // Bottom-left
        { X: x, Y: y },                  // Top-left
        { X: x + width, Y: y }           // Top-right
    ];
}

function convertShapesToSVGPath(shapes: Point[][]): string {
    return shapes
        .map(shape => {
            const pathData = shape.map((point, index) => {
                return index === 0
                    ? `M ${point.x} ${point.y}` // Move to the first point
                    : `L ${point.x} ${point.y}`; // Draw a line to subsequent points
            }).join(" ");

            return `${pathData} Z`; // Close the path for each shape
        })
        .join(" "); // Join all shapes with a space
}

function defaultBoundingBox(): BoundingBox {
    return {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    }
}

export namespace WidgetUtils {

    export const ClippedWidgetBody: FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = props => {
        const widget = useWidgetRenderContext();
        return (
            <div {...props} children={props.children} style={{
                mask: `url(#mask-shape-${widget.widgetId})`,
                width: "100%",
                height: "100%",
                overflow: "hidden",
                ...props.style
            }}/>
        );
    }
}

import {CSSProperties, FC, PropsWithChildren} from "react";
import styled from "styled-components";
import {Text} from "./Text";

const StyledTag = styled.div<{
    p: TagProps
}>`
    height: 35px;
    border-radius: 20px;
    background-color: ${p => p.p.backgroundColor ?? "black"};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 0 15px;
    cursor: pointer;
`;

export type TagProps<T = {}> = T & PropsWithChildren<Partial<{
    backgroundColor: string,
    onClick: () => void,
    style: CSSProperties
}>>

export const Tag: FC<TagProps> = props => {
    return (
        <StyledTag p={props} style={props.style} onClick={props.onClick}>
            { props.children }
        </StyledTag>
    );
}

export namespace TagVariants {

    export const TagWithText: FC<TagProps<{
        text: string | number;
    }>> = props => {
        return (
            <Tag {...props} children={
                <Text
                    text={props.text}
                    fontSize={"small"}
                    color={"auto"}
                    noSelect
                    whiteSpaceNoWrap
                    style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        padding: 0,
                        margin: 0,
                        fontWeight: "normal",
                    }}
                />
            }/>
        );
    }
}

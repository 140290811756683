import {FileData} from "./FileData";
import {Filesystem} from "./Filesystem";

export class File {

    private _src?: string;

    constructor(
        private readonly filesystem: Filesystem,
        public readonly data: FileData
    ) {}

    public async getSrc(bypassCache: boolean = false): Promise<string> {
        if (!bypassCache && this._src) {
            return this._src;
        }
        return this._src = this.data.src;
    }

    public setSrc(src: string): void {
        this._src = src;
    }

    // TODO: Set a dirty flag -> needs saving ~ live content may not be synced with fs persistence backend
    public write(text: string, append: boolean = false): void {
        const src = this._src ?? "";
        const newSrc = append ? src + text : text;
        this.setSrc(newSrc);
    }

    public async save(): Promise<this> {
        if (!this._src) return this;
        return this.updateDefinition(fd => {
            if (this._src) fd.src = this._src;
            return fd;
        });
    }

    public rename(newName: string): this {
        return this.updateDefinition(fd => {
            fd.name = newName;
            return fd;
        })
    }

    private updateDefinition(kernel: (fd: FileData) => FileData): this {
        this.filesystem.updateFileDefinition(this.data.id, kernel);
        return this;
    }

    public async copySrcFromFile(source: this): Promise<this> {
        this.setSrc(await source.getSrc());
        return this;
    }
}

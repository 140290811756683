import React, {FC, PropsWithChildren} from "react";
import {Page} from "../Page";
import {Text} from "../components/Text";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Enclave, EnclavePosition} from "../components/Enclave";
import {Widget, WidgetProps, WidgetUtils} from "../components/Widget";
import {Tag, TagVariants} from "../components/Tag";
import {WidgetBasedCardVariants} from "../components/WidgetBasedCard";
import {TypeAnimation} from "react-type-animation";
import {PrimaryDisplayTypography} from "../../v1/components/typography/PrimaryDisplayTypography";
import {Typography} from "../../v1/components/typography/Typography";
import {ToggleTarget} from "../../../triton/components/advanced/toggle/ToggleTarget";
import {AnimatePresence, motion} from "framer-motion";
import {AutoAwesomeRounded} from "@mui/icons-material";
import {ToggleController} from "../../../triton/components/advanced/toggle/ToggleController";
import {useTriton} from "../../../triton/TritonHooks";
import {useIsTabletOrMobile} from "../../../../std/MediaQueries";

const StyledPageHome = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    justify-content: space-between;
`;

export const PageHome: FC = props => {
    const navigate = useNavigate();
    const t = useTriton();

    const isMobile = useIsTabletOrMobile();

    if (isMobile) {
        return (
            <Page>
                <div style={{
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                }}>
                    <div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem"
                        }}>
                            <Text
                                fontSize={"large"}
                                noSelect
                                color={"auto"}
                                text={"// "}
                                style={{
                                    color: t.col("bg_modal")
                                }}
                            />
                            <Text
                                fontSize={"large"}
                                noSelect
                                color={"auto"}
                                text={"TODO:"}
                                style={{
                                    color: t.col("color_primary")
                                }}
                            />
                        </div>
                        <Text
                            fontSize={"large"}
                            noSelect
                            color={"auto"}
                            text={"Implement mobile page"}
                            style={{
                                color: t.col("color_primary")
                            }}
                        />
                    </div>
                    <Text
                        fontSize={"small"}
                        noSelect
                        color={"auto"}
                        text={"Visit this site on a desktop device for best experience"}
                        style={{
                            fontWeight: "lighter"
                        }}
                    />
                </div>
            </Page>
        );
    }

    return (
        <Page enableDefaultPadding>
            <StyledPageHome>

                <div style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "min-content auto",
                    gap: 20,
                }}>
                    <div>
                        <Text fontSize={"large"} noSelect whiteSpaceNoWrap color={"auto"}
                              text={"Christian Bernstein, Frankfurt"} style={{
                            color: t.col("color_primary")
                        }}/>
                        <Text fontSize={"large"} noSelect whiteSpaceNoWrap color={"auto"}
                              text={"Software Engineer"}/> {/* + " /" */}
                        {/*
                        <Typography sx={{
                            fontFamily: "JetBrains Mono",
                            fontSize: 48,
                            lineHeight: 1,
                            color: "white",
                            fontWeight: "bold",
                            userSelect: "none"
                        }}>
                            <TypeAnimation
                                cursor
                                preRenderFirstString
                                repeat={Infinity}
                                speed={{ type: 'keyStrokeDelayInMs', value: 200 }}
                                deletionSpeed={{ type: 'keyStrokeDelayInMs', value: 75 }}
                                sequence={[
                                    "UI Designer", 7.5e3,
                                    "Art enthusiast", 7.5e3,
                                    "Trumpet player", 7.5e3,
                                ]}
                            />
                        </Typography>
                        */}
                    </div>

                    <ToggleController>
                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "end",
                            flexDirection: "row",
                            gap: 20,
                            // height: "100%",
                            height: 150,
                        }}>
                            <ProjectCard
                                group={String(-1)}
                                title={""}
                                widgetPropsOverrides={{
                                    width: 300,
                                    height: 150
                                }}
                                additionalWidgetChildren={
                                    <Enclave position={EnclavePosition.TOP_LEFT}>
                                        <Tag backgroundColor={"#1E1E1E"} style={{
                                            height: 30
                                        }}>
                                            <AutoAwesomeRounded sx={{
                                                color: "white",
                                                fontSize: 18,
                                                marginRight: "-8px"
                                            }}/>
                                            <Text
                                                fontWeight={"lighter"}
                                                noSelect whiteSpaceNoWrap text={"NEW"} fontSize={"small"}
                                                color={"auto"}/>
                                        </Tag>
                                    </Enclave>
                                }
                            >
                                <img
                                    alt={""}
                                    width={"100%"}
                                    src={"https://i.pinimg.com/736x/8c/26/92/8c2692987741569fdac6821d78547643.jpg"}
                                />
                            </ProjectCard>

                            <div style={{
                                height: "50%",
                                width: 1,
                                backgroundColor: t.col("fg_muted"),
                                alignSelf: "center",
                                margin: 5,
                            }}/>

                            {[0, 1].map(key => (
                                <ProjectCard
                                    key={key}
                                    group={String(key)}
                                    title={""}
                                    widgetPropsOverrides={{
                                        width: 200,
                                        height: 150
                                    }}
                                >
                                    <img
                                        alt={""}
                                        width={"100%"}
                                        src={"https://i.pinimg.com/736x/8c/26/92/8c2692987741569fdac6821d78547643.jpg"}
                                    />
                                </ProjectCard>
                            ))}
                        </div>
                    </ToggleController>
                </div>

                <div style={{
                    width: "100%",
                    overflowX: "hidden",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                }}>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 38
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 30
                        }}>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"amet ipsir sitmum upsum"}/>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"amet ipsir sit"}/>
                        </div>

                        <div>
                            <Text fontSize={"x-large"} noSelect whiteSpaceNoWrap color={"auto"} text={"Hey,"}/>
                            <Text fontSize={"x-large"} noSelect whiteSpaceNoWrap color={"auto"}
                                  text={"I create software since 2019"}/>
                            <Text fontSize={"x-large"} noSelect whiteSpaceNoWrap color={"auto"} text={"merit temum mus amimet"}
                                  style={{
                                      color: t.col("color_primary")
                                  }}/>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 45
                        }}>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"LinkedIn"}/>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"GitHub"}/>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"Twitter"}/>
                        </div>
                    </div>


                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 250px)",
                        gridTemplateRows: "repeat(2, 125px)",
                        gap: 30
                    }}>
                        <WidgetBasedCardVariants.ValueWithLabel
                            value={"6y"}
                            label={"Experience"}
                        />
                        <WidgetBasedCardVariants.ValueWithLabel
                            value={"3.7k"}
                            label={"Git commits"}
                            widgetChildren={
                                <Enclave position={1}>
                                    <TagVariants.TagWithText
                                        text={"GitHub"}
                                        backgroundColor={"#1E1E1E"}
                                    />
                                </Enclave>
                            }
                        />
                        <WidgetBasedCardVariants.ValueWithLabel
                            value={"+80"}
                            label={"Projects"}
                        />
                        <WidgetBasedCardVariants.ValueWithLabel
                            value={"many"}
                            label={"hours wasted"}
                        />
                    </div>
                </div>

            </StyledPageHome>
        </Page>
    );
}

const ProjectCard: FC<PropsWithChildren<{
    group: string,
    title?: string,
    description?: string,
    widgetPropsOverrides?: Partial<WidgetProps>
    additionalWidgetChildren?: React.ReactNode
}>> = props => {
    const {group} = props;

    return (
        <ToggleTarget group={group} children={ctx => (
            <Widget width={250} height={250} widgetWrapper={(widget, widgetContext) => (
                <motion.div
                    children={widget}
                    onHoverStart={ctx.requestFocus}
                    onHoverEnd={ctx.requestUnFocus}
                    onAnimationComplete={widgetContext.requestRerender}
                    style={{
                        y: 0,
                    }}
                    animate={{
                        filter: `grayscale(${
                            ctx.isAnyActive ? (
                                ctx.isActive ? 0 : 100
                            ) : 0
                        }%)`,
                        y: ctx.isAnyActive ? (
                            ctx.isActive ? -2.5 : 2.5
                        ) : 0,
                    }}
                    whileTap={{
                        y: 0
                    }}
                />
            )} {...props.widgetPropsOverrides}>
                {props.additionalWidgetChildren}
                <Enclave position={EnclavePosition.TOP_RIGHT} children={
                    <div style={{
                        aspectRatio: "1 / 1",
                        width: 30,
                        position: "relative",
                    }}>
                        <motion.div style={{
                            backgroundColor: "#1E1E1E",
                            borderRadius: "50%",
                            aspectRatio: "1 / 1",
                            width: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            position: "absolute",
                        }} whileHover={{
                            top: -2.5,
                            right: -2.5,
                        }} whileTap={{
                            scale: 1.2
                        }} children={
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect y="6.10352e-05" width="13.9819" height="0.874996" fill="#D9D9D9"/>
                                <rect x="13.9819" y="6.10352e-05" width="13.9999" height="0.873867"
                                      transform="rotate(90 13.9819 6.10352e-05)" fill="#D9D9D9"/>
                                <rect width="14.1626" height="0.874431"
                                      transform="matrix(-0.70665 0.707563 -0.70665 -0.707563 14 0.618713)"
                                      fill="#D9D9D9"/>
                            </svg>
                        }/>
                    </div>
                }/>

                <WidgetUtils.ClippedWidgetBody style={{
                    cursor: "pointer",
                }}>
                    {props.children}

                    <motion.div style={{
                        zIndex: 1,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        pointerEvents: "none",
                        background: "linear-gradient(transparent, black)",
                    }} animate={{
                        opacity: ctx.isActive ? .75 : .5,
                    }}/>

                    <motion.div
                        style={{
                            zIndex: 2,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            padding: 20,
                            pointerEvents: "none"
                        }}
                    >
                        <Text text={props.title} color={"auto"} fontSize={18}/>

                        <AnimatePresence>
                            { ctx.isActive && (
                                <motion.div
                                    layout
                                    style={{
                                        overflow: "hidden",
                                    }}
                                    animate={{
                                        height: "auto",
                                        opacity: 1
                                    }}
                                    initial={"off"}
                                    exit={"off"}
                                    variants={{
                                        off: {
                                            height: 0,
                                            opacity: 0
                                        }
                                    }}
                                >
                                    <div style={{
                                        paddingTop: 8
                                    }}>
                                        <Text text={props.description} color={"auto"} fontSize={"small"} fontWeight={"normal"}/>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                    </motion.div>
                </WidgetUtils.ClippedWidgetBody>
            </Widget>
        )}/>
    );
}

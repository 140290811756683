import {FC, ReactNode} from "react";
import {ToggleTargetContext} from "./ToggleTargetContext";
import {ToggleControllerBridge} from "./ToggleController";

export type ToggleTargetProps = {
    group: string | number,
    children: (ctx: ToggleTargetContext) => ReactNode;
}

export const ToggleTarget: FC<ToggleTargetProps> = props => {
    const { group } = props;
    const controller = ToggleControllerBridge.useBackend();

    return (
        <>{
            props.children({
                isActive: controller.isActive(group),
                isAnyActive: controller.state.activeGroup !== undefined,
                requestFocus() {
                    return controller.toggle(group);
                },
                requestUnFocus() {
                    return controller.toggle(group);
                }
            })
        }</>
    );
}

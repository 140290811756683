import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import React, {FC} from "react";
import {useTriton} from "../../../../triton/TritonHooks";
import {DynamicCodeEditor} from "./components/DynamicCodeEditor";
import styled from "styled-components";

export const NodeDynamicCodeEditor: NodeSetupInfo = {
    label: "NodeDynamicCodeEditor",
    classname: "vsm.dynamic-code-editor",
    parameterConfig: [],
    factory(parameters: Map<string, any>): Node {
        return new NodeDynamicCodeEditorClass()
    }
}

export class NodeDynamicCodeEditorClass extends Node {
    constructor() {
        super({
            id: v4(),
            label: "code editor",
            classname: "vsm.dynamic-code-editor",
            customRenderer: node => {
                return (
                    <NodeDynamicCodeEditorDisplay node={node as NodeDynamicCodeEditorClass}/>
                );
            }
        });
    }
}

const StyledNodeDynamicCodeEditorDisplay = styled.div`
    padding-top: 8px;
    padding-bottom: 8px;
`;

const NodeDynamicCodeEditorDisplay: FC<{
    node: NodeDynamicCodeEditorClass
}> = props => {
    const { node } = props;
    const t = useTriton();

    return (
        <StyledNodeDynamicCodeEditorDisplay>
            <DynamicCodeEditor
                onChange={code => {}}
            />
        </StyledNodeDynamicCodeEditorDisplay>
    );
}

import {LoadAndSaveAdapter} from "./LoadAndSaveAdapter";
import {ProjectData} from "./ProjectData";
import {Filesystem} from "./Filesystem";
import {Configurable} from "../../../sat/common/core/Configurable";
import {Omit} from "react-beautiful-dnd";
import {v4} from "uuid";
import {StandaloneObservable} from "../../../ardai/webapi/pubsub/StandaloneObservable";
import {FileData} from "./FileData";

export class Project extends Configurable<{
    loadAndSaveAdapter: LoadAndSaveAdapter,
    fileIdGenerator: Project.IDGenerator
}, "loadAndSaveAdapter"> {

    public readonly events = new StandaloneObservable();

    private data?: ProjectData;

    private _filesystem?: Filesystem;

    private get loadAndSaveAdapter(): LoadAndSaveAdapter {
        return this.config.loadAndSaveAdapter;
    }

    public async save(): Promise<void> {
        return this.loadAndSaveAdapter.save(this);
    }

    public async load(): Promise<void> {
        this.data = await this.loadAndSaveAdapter.load();

        this._filesystem = new Filesystem(this, this.data.filesystem);

        this.events.notify("", undefined)

        // TODO: Inflate this
    }

    public async deflate(): Promise<ProjectData> {
        const data = this.data!;
        data.filesystem = await this.fs.deflate();
        return data;
    }

    public get fs(): Filesystem {
        return this._filesystem!;
    }

    protected get defaultConfig(): Readonly<Omit<{
        loadAndSaveAdapter: LoadAndSaveAdapter;
        fileIdGenerator: Project.IDGenerator
    }, "loadAndSaveAdapter">> {
        return {
            fileIdGenerator: {
                next(): string {
                    return v4()
                }
            }
        };
    }
}

export namespace Project {
    export interface IDGenerator {
        next(): string;
    }

    export function createMockProject(): Project {
        return new Project({
            loadAndSaveAdapter: new class implements LoadAndSaveAdapter {
                load(): Promise<ProjectData> {
                    return Promise.reject("mock filesystem cannot be loaded");
                }
                save(): Promise<void> {
                    return Promise.reject("mock filesystem cannot be saved");
                }
            }
        })
    }
}

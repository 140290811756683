import {FC, PropsWithChildren, useEffect, useRef} from "react";
import styled from "styled-components";
import {useForceRenderFunc} from "../../../ForceRerenderHook";
import {useWidgetRenderContext} from "./Widget";

export type EnclaveProps = PropsWithChildren<{
    position?: EnclavePosition
    isShapeSubtractionTarget?: boolean
}>;

export enum EnclavePosition {
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM_RIGHT,
    BOTTOM_LEFT,
}

const enclavePositionLookup: { [K in EnclavePosition]: [boolean, boolean, boolean, boolean] } = {
    [EnclavePosition.TOP_LEFT]: [true, false, false, true],
    [EnclavePosition.TOP_RIGHT]: [true, true, false, false],
    [EnclavePosition.BOTTOM_RIGHT]: [false, true, true, false],
    [EnclavePosition.BOTTOM_LEFT]: [false, false, true, true],
}

// noinspection CssUnresolvedCustomProperty
const StyledEnclave = styled.div<{
    enclavePosition: EnclavePosition;
}>`
    z-index: 1;
    
    position: absolute;
    
    top: ${p => enclavePositionLookup[p.enclavePosition][0] ? "0" : "auto"};
    right: ${p => enclavePositionLookup[p.enclavePosition][1] ? "0" : "auto"};
    bottom: ${p => enclavePositionLookup[p.enclavePosition][2] ? "0" : "auto"};
    left: ${p => enclavePositionLookup[p.enclavePosition][3] ? "0" : "auto"};
`;

export const Enclave: FC<EnclaveProps> = props => {
    const position = props.position || EnclavePosition.TOP_LEFT;
    const isShapeSubtractionTarget = props.isShapeSubtractionTarget ?? true;

    const context = useWidgetRenderContext();

    return (
        <StyledEnclave enclavePosition={position}>

            <div className={isShapeSubtractionTarget ? "widget-subtract-bb" : ""} data-target-widget={context.widgetId} style={{
                position: "relative"
            }}>
                { props.children }
            </div>

        </StyledEnclave>
    );
}

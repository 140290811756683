import {FC} from "react";
import {Page} from "../Page";
import {Text} from "../components/Text";
import {useTriton} from "../../../triton/TritonHooks";

export const PagePersonalProjects: FC = props => {
    const t = useTriton();
    return (
        <Page enableDefaultPadding>
            <Text fontSize={"x-large"} text={"Personal Projects"} color={t.col("color_primary")}/>
        </Page>
    );
}

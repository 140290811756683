import {FC, useEffect, useRef, useState} from "react";
import {Widget, WidgetUtils} from "../portfolio/v2/components/Widget";
import {Enclave, EnclavePosition} from "../portfolio/v2/components/Enclave";
import {motion, useAnimationFrame} from "framer-motion";
import {useTriton} from "../triton/TritonHooks";
import {TagVariants} from "../portfolio/v2/components/Tag";
import {Text} from "../portfolio/v2/components/Text";
import {WidgetBasedCardVariants} from "../portfolio/v2/components/WidgetBasedCard";
import {PortfolioMain} from "../portfolio/v2/PortfolioMain";
import {TritonReactBridge} from "../triton/TritonReactBridge";
import {Triton} from "../triton/Triton";
import {TritonDefaults} from "../triton/TritonDefaults";

export const SimpleComponentTestingSpace: FC = props => {
    // const t = useTriton();
    // const [d, setD] = useState(1);
    // const ref = useRef(0);
    // useAnimationFrame((time) => {
    //     ref.current += .075;
    //     if (ref.current > 1) {
    //         setD(prevState => (prevState + .025) % 1);
    //         ref.current = 0;
    //     }
    // });

    return (
        <PortfolioMain/>
    );

    return (
        <div style={{
            padding: "65px 100px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            background: "black",
        }}>
            <div style={{
                width: "100%",
                height: "100%",
                display: "grid",
                gridTemplateRows: "min-content auto",
            }}>
                <div style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <div style={{
                        display: "flex",
                        gap: 40
                    }}>
                        <div style={{
                            aspectRatio: "1 / 1",
                            width: 35,
                            backgroundColor: "#63D145",
                            borderRadius: 100,
                        }}/>

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: 20
                        }}>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"itmus sit"}/>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"ametius lori"}/>
                            <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"lorem"}/>
                        </div>
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: 40
                    }}>
                        <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"itmus sit"}/>
                        <TagVariants.TagWithText backgroundColor={"#414141"} text={"_______________________"}/>
                    </div>
                </div>

                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    gridTemplateRows: "min-content auto",
                }}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                    }}>

                        <div>
                            <Text text={"Lorem ipsum sit"} fontSize={"large"}/>
                            <Text text={"amet ipsir"} fontSize={"large"} style={{
                                color: "#63D145"
                            }}/>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 30,
                            height: "200px",
                        }}>
                            <Widget width={250} height={"100%"}>
                                <Enclave position={EnclavePosition.TOP_RIGHT} children={
                                    <div style={{
                                        backgroundColor: "#1E1E1E",
                                        borderRadius: "50%",
                                        aspectRatio: "1 / 1",
                                        width: 30
                                    }}/>
                                }/>

                                <WidgetUtils.ClippedWidgetBody>
                                    <img
                                        alt={""}
                                        width={"100%"}
                                        src={"https://i.pinimg.com/736x/51/e3/10/51e3105cad8596bc59962bbd01e9d6bd.jpg"}
                                     />
                                </WidgetUtils.ClippedWidgetBody>
                            </Widget>

                            <Widget width={250} height={"100%"}>
                                <Enclave position={EnclavePosition.TOP_RIGHT} children={
                                    <div style={{
                                        backgroundColor: "#1E1E1E",
                                        borderRadius: "50%",
                                        aspectRatio: "1 / 1",
                                        width: 30
                                    }}/>
                                }/>

                                <WidgetUtils.ClippedWidgetBody>
                                    <img
                                        alt={""}
                                        width={"100%"}
                                        src={"https://i.pinimg.com/736x/8c/26/92/8c2692987741569fdac6821d78547643.jpg"}
                                    />
                                </WidgetUtils.ClippedWidgetBody>
                            </Widget>

                            <TestWidget/>
                            <TestWidget/>
                        </div>
                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 38
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 30
                            }}>
                                <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"amet ipsir sitmum upsum"}/>
                                <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"amet ipsir sit"}/>
                            </div>

                            <div>
                                <Text noSelect fontSize={"x-large"} style={{

                                }} text={"Lorem ipsum sit"}/>
                                <Text noSelect fontSize={"x-large"} style={{

                                }} text={"amet ipsir"}/>
                                <Text noSelect fontSize={"x-large"} style={{
                                    color: "#63D145"
                                }} text={"merit temum mus amimet"}/>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 45
                            }}>
                                <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"T"}/>
                                <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"V"}/>
                                <TagVariants.TagWithText backgroundColor={"#1E1E1E"} text={"K"}/>
                            </div>
                        </div>

                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 250px)",
                            gridTemplateRows: "repeat(2, 125px)",
                            gap: 30
                        }}>
                            <WidgetBasedCardVariants.ValueWithLabel
                                value={"6y"}
                                label={"Experience"}
                            />
                            <WidgetBasedCardVariants.ValueWithLabel
                                value={"3.7k"}
                                label={"Git commits"}
                                widgetChildren={
                                    <Enclave position={1}>
                                        <TagVariants.TagWithText
                                            text={"GitHub"}
                                            backgroundColor={"#1E1E1E"}
                                        />
                                    </Enclave>
                                }
                            />
                            <WidgetBasedCardVariants.ValueWithLabel
                                value={"+80"}
                                label={"Projects"}
                            />
                            <WidgetBasedCardVariants.ValueWithLabel
                                value={"many"}
                                label={"hours wasted"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const TestWidget: FC = () => {
    return (
        <Widget
            width={400}
            height={200}
            // fill={"#63D145"}
            fill={"#1E1E1E"}
            boundingBoxPadding={5}
            borderRoundness={20}
            // widgetBackgroundPattern={
            //     <image
            //         href={"https://images.unsplash.com/photo-1507041957456-9c397ce39c97?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Zm9yZXN0fGVufDB8fDB8fHww"}
            //         width={"100%"}
            //     />
            // }
        >

            <Enclave position={1}>
                <Widget
                    width={300}
                    height={150}
                    boundingBoxPadding={5}
                    borderRoundness={20}
                    fill={"url(#nice-gb)"}
                    additionalSVGBackgroundPatterns={
                        <>
                            <pattern id="dots" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                                <circle cx="5" cy="5" r="1" fill="#63D145" opacity={.2}/>
                            </pattern>

                            <pattern id="nice-gb" x="0" y="0" width="100%" height="100%"
                                     patternUnits="userSpaceOnUse">
                                <rect fill={"#1E1E1E"} x="0" y="0" width={"100%"} height={"100%"}/>
                                <rect fill={"url(#dots)"} x="0" y="0" width={"100%"} height={"100%"}/>
                            </pattern>
                        </>
                    }
                >
                    {ctx => (
                        <>
                            <Enclave position={1} isShapeSubtractionTarget={false}>
                                <motion.div className={"widget-subtract-bb"} data-target-widget={ctx.widgetId}
                                            style={{

                                                height: 30,
                                                width: 30,

                                                border: "1px solid #63D145",
                                                borderRadius: 100
                                            }} whileHover={{
                                    scale: 1.1
                                }}/>
                            </Enclave>

                            <Enclave position={0}>
                                <TagVariants.TagWithText
                                    text={"Hello world"}
                                    backgroundColor={"#1E1E1E"}
                                />
                            </Enclave>

                            <div style={{
                                padding: 20,
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "end",
                                gap: 20,
                                userSelect: "none",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 4
                                }}>
                                    <Text text={"10"} fontSize={"medium"}/>
                                    <Text text={"hours wasted"} style={{
                                        color: "#63D145"
                                    }}/>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 4
                                }}>
                                    <Text text={"3"}/>
                                    <Text text={"iterations"} style={{
                                        color: "#63D145"
                                    }}/>
                                </div>
                            </div>
                        </>
                    )}
                </Widget>
            </Enclave>

            <Enclave position={3}>
                <motion.div style={{

                    height: 30,
                    width: 30,

                    // border: "1px solid #63D145",
                    borderRadius: 100
                }} whileHover={{
                    scale: 1.1
                }}/>
            </Enclave>
        </Widget>
    );
}

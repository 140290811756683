export type UpdateFunction<T extends {}> = Partial<T> | ((obj: T) => Partial<T>)

export function runUpdateFunction<T extends {}>(data: T, kernel: UpdateFunction<T>): T {
    let newData: T = data;
    if (typeof kernel === "function") {
        const kernelResult: Partial<T> = (kernel as any)(data);
        if (kernelResult !== undefined) {
            newData = {
                ...data,
                ...kernelResult
            };
        }
    } else newData = newData = {
        ...data,
        ...kernel as Partial<T>
    };
    return newData;
}

import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {NodeDynamicCodeEditorClass} from "./NodeDynamicCodeEditor";
import {v4} from "uuid";
import React, {FC} from "react";
import {DynamicCodeEditor} from "./components/DynamicCodeEditor";

export const NodeCompiler: NodeSetupInfo = {
    label: "NodeCompiler",
    classname: "vsm.compiler",
    parameterConfig: [],
    factory(parameters: Map<string, any>): Node {
        return new NodeCompilerClass()
    }
}

export class NodeCompilerClass extends Node {
    constructor() {
        super({
            id: v4(),
            label: "compiler",
            classname: "vsm.compiler",
            customRenderer: node => {
                return (
                    <NodeDynamicCodeEditorDisplay node={node as NodeDynamicCodeEditorClass}/>
                );
            }
        });
    }
}

const NodeDynamicCodeEditorDisplay: FC<{
    node: NodeDynamicCodeEditorClass
}> = props => {
    const { node } = props;

    return (
        <div style={{
            paddingBottom: 8,
            paddingTop: 8
        }}>
            <DynamicCodeEditor
                onChange={code => {}}
            />
        </div>
    );
}

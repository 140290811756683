import {FileSystemData} from "./FileSystemData";
import {File} from "./File";
import {FileData} from "./FileData";
import {v4} from "uuid";
import {FileType} from "./FileType";
import {runUpdateFunction, UpdateFunction} from "../../../../std/UpdateFunction";
import {Project} from "./Project";
import {omit} from "lodash";
import {DirectorySubSystem} from "./directories/DirectorySubSystem";

export class Filesystem {

    /**
     * key: file internal id (FileData->id)
     *
     * @private
     */
    private _openFiles: Map<string, File> = new Map<string, File>();

    public readonly directories: DirectorySubSystem;

    constructor(
        private readonly project: Project,
        public data: FileSystemData
    ) {
        this.directories = new DirectorySubSystem(this);
    }

    public async deflate(): Promise<FileSystemData> {
        for (let file of Array.from(this._openFiles.values())) {
            await file.save();
        }
        this.data.directoryStructure = await this.directories.deflate();
        return this.data;
    }

    /**
     * Returns all file data entries
     */
    public get allFileDataEntries(): Array<FileData> {
        return this.data.files;
    }

    private updateFileSystemData(kernel: UpdateFunction<FileSystemData>) {
        this.data = runUpdateFunction(this.data, kernel);
        this.project.events.notify("...", undefined) // TODO: Make final implementation
    }

    public deleteAllFiles(): void {
        this.allFileDataEntries.forEach((fd) =>{
            this.deleteFile(fd.id);
        });
    }

    public deleteFile(internalFileId: string) {
        // Get all external file references that point to the file
        const correspondingExtStructure = Object.entries(this.data.structure)
            .filter(([extId, intId]) => intId === internalFileId)

        // Close all those files
        if (this._openFiles.has(internalFileId)) {
            // TODO: Requires closing? like file.close()
            this._openFiles.delete(internalFileId);
        }

        // Delete the structure and file
        this.updateFileSystemData(obj => ({
            files: obj.files.filter(file => file.id !== internalFileId),
            structure: omit(obj.structure, correspondingExtStructure.map(([extId]) => extId)),
        }))

    }

    public createFile(name: string, structureKey: string | undefined = undefined): Filesystem.FileCreationContext {
        const newFile: FileData = {
            name,
            id: this.project.config.fileIdGenerator.next(),
            src: "",
            fileType: FileType.DIRECT,
            mimeType: ""
        }
        this.updateFileSystemData(obj => ({
            files: [...obj.files, newFile],
            structure: {
                ...obj.structure,
                [structureKey ?? newFile.id]: newFile.id
            }
        }));
        const thisRef = this;
        return {
            data: newFile,
            get file(): File {
                return thisRef.getFileFromInternalFileId(newFile.id);
            }
        };
    }

    public getFileFromInternalFileId(internalFileId: string): File {
        if (this._openFiles.has(internalFileId)) {
            return this._openFiles.get(internalFileId)!;
        }
        const fileData = this.getFileData(internalFileId);
        const file = new File(this, fileData);
        this._openFiles.set(internalFileId, file);
        return file;
    }

    public getFileOptimistically(externalFileId: string): File {
        const internalFileId = this.data.structure[externalFileId];
        return this.getFileFromInternalFileId(internalFileId);
    }

    public getFile(externalFileId: string): Filesystem.QueryResult<File> {
        kernel: {
            const internalFileId = this.data.structure[externalFileId];
            if (internalFileId === undefined) break kernel;
            if (!this.hasFile(internalFileId)) break kernel;
            return Filesystem.createQueryResult(this.getFileFromInternalFileId(internalFileId));
        }
        return Filesystem.createQueryResult();
    }

    public hasFile(internalFileId: string): boolean {
        return this.data.files.some(file => file.id === internalFileId);
    }

    public updateFileDefinition(internalFileId: string, kernel: (fd: FileData) => FileData): void {
        let fileData = this.getFileData(internalFileId);
        fileData = kernel(fileData);
        const idx = this.data.files.findIndex(fd => fd.id === internalFileId);
        this.data.files[idx] = fileData;
    }

    /**
     * TODO: Meta data etc isn't yet copied! Only the source is
     *
     * @param internalFileId
     * @param newFileName
     */
    public async duplicateFile(internalFileId: string, newFileName: string): Promise<Filesystem.FileCreationContext> {
        const file = this.getFileFromInternalFileId(internalFileId);
        const duplicate = this.createFile(newFileName).file;
        await duplicate.copySrcFromFile(file);

        return {
            data: duplicate.data,
            file: duplicate
        }
    }

    private getFileData(internalFileId: string): FileData {
        const fileData = this.data.files.find(fd => fd.id === internalFileId);
        if (!fileData) throw new Error(`Cannot find file (internalFileId='${internalFileId}')`); // TODO: Add better error handling
        return fileData;
    }
}

export namespace Filesystem {

    export interface FileCreationContext {
        data: FileData;
        get file(): File;
    }

    export interface QueryResult<T> {
        get value(): T;
        hasValue: boolean;
    }

    export function createQueryResult<T>(value: T | undefined = undefined): QueryResult<T> {
        return {
            hasValue: value !== undefined,
            get value(): T {
                if (value === undefined) throw new Error("Cannot get value of query result: Result is undefined");
                return value!;
            }
        }
    }

    export function createDefaultFilesystemData(): FileSystemData {
        return {
            files: [],
            structure: {},
            directoryStructure: {
                root: {
                    id: "root",
                    files: [],
                    subDirectories: []
                }
            }
        };
    }
}

import React, {FC} from "react";
import {Header} from "./Header";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {PageHome} from "./pages/PageHome";
import {Page} from "./Page";
import {Text} from "./components/Text";
import {Tag} from "../../ardai/components/Tag";
import {PagePersonalProjects} from "./pages/PagePersonalProjects";
import {ReactMaster} from "../../ReactAPIBridgeUtils";
import {PortfolioAPIV2Bridge} from "./PortfolioAPIV2";
import {PageProjects} from "./pages/PageProjects";
import {useTriton} from "../../triton/TritonHooks";

export const PortfolioMain: FC = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const t = useTriton();

    return (
        <ReactMaster fx={PortfolioAPIV2Bridge}>
            <div style={{
                width: "100vw",
                height: "100vh",
                overflow: "hidden",

                display: "grid",
                gridTemplateRows: "min-content auto",
                gap: 30
            }}>
                <Header/>

                <div>
                    <Routes location={location}>
                        <Route index element={<PageHome/>}/>
                        <Route path="/404" element={
                            <Page enableDefaultPadding>
                                <Text
                                    fontSize={"x-large"}
                                    text={"404"}
                                    color={t.col("color_primary")}
                                />
                                <Text
                                    fontSize={"x-large"}
                                    color={"auto"}
                                    text={"This page doesn't exist"}
                                />
                                <Tag tag={"home"} onClick={() => {
                                    navigate("")
                                }}/>

                            </Page>
                        }/>

                        <Route path={"projects"}>
                            <Route
                                index
                                element={<PageProjects/>}
                            />

                            <Route
                                path={"personal"}
                                element={<PagePersonalProjects/>}
                            />

                            <Route
                                path={"*"}
                                element={<>ADD NoMatch component</>}
                            />
                        </Route>



                        <Route path={"*"} element={
                            <Page enableDefaultPadding>
                                <Text
                                    fontSize={"x-large"}
                                    text={"404"}
                                    color={t.col("color_primary")}
                                />
                                <Text
                                    fontSize={"x-large"}
                                    color={"auto"}
                                    text={"This page doesn't exist"}
                                />
                                <Tag tag={"home"} onClick={() => {
                                    navigate("")
                                }}/>

                            </Page>
                        }/>

                        <Route path={"*"} element={
                            <Navigate
                                to={"404"}
                            />
                        }/>
                    </Routes>
                </div>
            </div>
        </ReactMaster>
    );
}

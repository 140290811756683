import {Filesystem} from "../Filesystem";
import {DirectoryStructureData} from "./DirectoryStructureData";

export class DirectorySubSystem {

    private _data: DirectoryStructureData;

    constructor(public readonly fs: Filesystem) {
        this._data = fs.data.directoryStructure;
    }

    public async deflate(): Promise<DirectoryStructureData> {
        return this._data;
    }
}
